import React from "react";

function PromoCodeIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.0002 8.33366C13.0002 7.41366 13.7468 6.66699 14.6668 6.66699V6.00033C14.6668 3.33366 14.0002 2.66699 11.3335 2.66699H4.66683C2.00016 2.66699 1.3335 3.33366 1.3335 6.00033V6.33366C2.2535 6.33366 3.00016 7.08033 3.00016 8.00033C3.00016 8.92033 2.2535 9.66699 1.3335 9.66699V10.0003C1.3335 12.667 2.00016 13.3337 4.66683 13.3337H11.3335C14.0002 13.3337 14.6668 12.667 14.6668 10.0003C13.7468 10.0003 13.0002 9.25366 13.0002 8.33366Z"
        stroke={color || "#BAB8B8"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 2.66699L6.6665 13.3337"
        stroke={color || "#BAB8B8"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="5 5"
      />
    </svg>
  );
}

export default PromoCodeIcon;
