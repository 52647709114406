import React, { useEffect, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Circle,
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { IconArrowLeft } from "@tabler/icons-react";
import CustomText from "../../components/text/CustomText";
import FilledCircle from "../../assets/images/filled-circle.svg";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisitorById,
  updateVisitor,
} from "../../redux/actions/visitors.actions";
import { useSendEmail } from "../../utils/wds.api";

function VisitorDetails() {
  const navigate = useNavigate();

  const sendEmail = useSendEmail();

  const dispatch: any = useDispatch();
  const { visitorInfo } = useSelector((state: any) => state.visitors);
  const { user } = useSelector((state: any) => state.user);

  const toast = useToast();

  const { id } = useParams();

  const [loadingFor, setLoadingFor] = useState("");
  const [remarks, setRemarks] = useState("");
  const [kycInfo, setKycInfo] = useState<any>({
    profileImg: false,
    documents: false,
  });

  useEffect(() => {
    dispatch(getVisitorById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (visitorInfo) {
      setKycInfo({
        profileImg: visitorInfo?.kycInfo?.profileImg ? true : false,
        documents: visitorInfo?.kycInfo?.documents ? true : false,
      });
    }
  }, [visitorInfo]);

  return (
    <CustomCard>
      <Flex flexDir="column" gap="24px">
        <Flex align="center" gap="16px">
          <IconArrowLeft
            size={24}
            color="#387DF5"
            cursor="pointer"
            onClick={() => {
              navigate("/visitors");
            }}
          />
          {!visitorInfo ? (
            <SkeletonText noOfLines={1} w="200px" />
          ) : (
            <CustomText
              text={`${visitorInfo?.personalInfo?.name} Vetting Profile Detail`}
              size="18px"
              color="yess.base_black"
            />
          )}
        </Flex>
        <Flex gap="24px">
          <Flex flexDir="column" w="100%">
            <Flex align="center" justify="space-between">
              {!visitorInfo ? (
                <Skeleton h="134px" w="134px" borderRadius="8px" />
              ) : (
                <Avatar
                  boxSize="134px"
                  bg="yess.secondary_gold"
                  name={visitorInfo?.personalInfo?.name}
                  src={visitorInfo?.profileImg}
                  borderRadius="8px"
                />
              )}
              <Flex gap="10px" align="center">
                <CustomText
                  text="Real Image"
                  color="yess.neutral500"
                  mt="-5px"
                />
                <Checkbox
                  size="lg"
                  isChecked={kycInfo?.profileImg}
                  onChange={(e) => {
                    setKycInfo({
                      ...kycInfo,
                      profileImg: e.target.checked,
                    });
                  }}
                />
              </Flex>
            </Flex>
            <CustomText
              text="Personal Info"
              color="yess.neutral500"
              mt="16px"
            />
            <Flex
              mt="8px"
              flexDir="column"
              gap="16px"
              p="16px"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.secondary_light_gray"
            >
              <Flex align="center" justify="space-between">
                <CustomText text="Name" color="yess.neutral500" />
                {!visitorInfo ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.personalInfo?.name}
                      color="yess.neutral800"
                    />
                    {/* <Checkbox size="lg" isChecked /> */}
                  </Flex>
                )}
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="Nationality" color="yess.neutral500" />
                {!visitorInfo ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.personalInfo?.nationality}
                      color="yess.neutral800"
                    />
                    {/* <Checkbox size="lg" /> */}
                  </Flex>
                )}
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="DOB" color="yess.neutral500" />
                {!visitorInfo ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.personalInfo?.dateOfBirth}
                      color="yess.neutral800"
                    />
                    {/* <Checkbox size="lg" /> */}
                  </Flex>
                )}
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="Address" color="yess.neutral500" />
                {!visitorInfo ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.personalInfo?.personalAddress}
                      color="yess.neutral800"
                    />
                    {/* <Checkbox size="lg" /> */}
                  </Flex>
                )}
              </Flex>
            </Flex>
            <CustomText
              text="Uploaded Documents"
              color="yess.neutral500"
              mt="16px"
            />
            <Flex
              mt="8px"
              gap="24px"
              p="16px"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.secondary_light_gray"
            >
              {!visitorInfo
                ? new Array(3)?.fill(1)?.map((_, idx) => (
                    <Flex flexDir="column" gap="12px" key={idx}>
                      <Skeleton h="134px" w="134px" borderRadius="8px" />
                      <Flex justify="space-between" gap="16px" align="center">
                        <SkeletonText noOfLines={1} w="134px" />
                      </Flex>
                    </Flex>
                  ))
                : visitorInfo?.documentUrls?.map(
                    (document: any, index: number) => (
                      <Flex key={index} flexDir="column" gap="12px">
                        <Image
                          src={document}
                          alt="passport"
                          height="134px"
                          maxW="150px"
                          objectFit="cover"
                          borderRadius="8px"
                        />
                        <Flex justify="space-between" gap="16px" align="center">
                          <CustomText
                            text={
                              visitorInfo?.documentUrls?.length === 1
                                ? "Passport"
                                : index === 0
                                ? "Iqama (Front)"
                                : "Iqama (Back)"
                            }
                            size="14px"
                            color="yess.neutral1000"
                          />
                          <Checkbox
                            size="lg"
                            isChecked={kycInfo?.documents}
                            onChange={(e) => {
                              setKycInfo({
                                ...kycInfo,
                                documents: e.target.checked,
                              });
                            }}
                          />
                        </Flex>
                      </Flex>
                    )
                  )}
            </Flex>
            <CustomText text="Your Remarks" color="yess.neutral500" mt="16px" />
            <Textarea
              mt="8px"
              p="16px"
              borderRadius="8px"
              border="1px solid #F2F2F2"
              placeholder="Type here..."
              fontSize="14px"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
            <Flex
              mt="16px"
              gap="16px"
              display={
                visitorInfo?.status?.toUpperCase() === "APPROVED"
                  ? "none"
                  : "flex"
              }
            >
              <Button
                h="48px"
                w="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                bg="#2EC19E"
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                _hover={{
                  bg: "#2EC19E",
                }}
                isDisabled={loadingFor === "REJECT" || sendEmail?.isPending}
                isLoading={loadingFor === "APPROVE"}
                onClick={() => {
                  setLoadingFor("APPROVE");
                  dispatch(
                    updateVisitor(
                      id,
                      {
                        status:
                          user?.role !== "superadmin"
                            ? "LEVEL_2_PENDING"
                            : "APPROVED",
                        remarks,
                        kycInfo,
                        kycHistory: [
                          ...visitorInfo.kycHistory,
                          {
                            action:
                              user?.role !== "superadmin"
                                ? "Level 2 Pending"
                                : "Approved",
                            name: user?.name,
                            createdAt: new Date()?.toString(),
                            status:
                              user?.role !== "superadmin"
                                ? "Level 2 Pending"
                                : "Approved",
                            description: remarks || "No Remarks",
                          },
                        ],
                      },
                      () => {
                        toast({
                          description: "Visitor Approved Successfully",
                          status: "success",
                          duration: 3000,
                        });
                        setLoadingFor("");
                        setRemarks("");
                      }
                    )
                  );
                  sendEmail?.mutateAsync({
                    from: "noreply@yess.com",
                    to: [visitorInfo?.personalInfo?.email],
                    subject: `KYC Update!`,
                    html: `
                        <div>
                        <p>Your KYC has been updated. Please check your profile for more details.</p>
                        </div>
                      `,
                    body: "",
                  });
                }}
              >
                Approve
              </Button>
              <Button
                h="48px"
                w="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                bg="#ED3D4E"
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                _hover={{
                  bg: "#ED3D4E",
                }}
                isDisabled={loadingFor === "APPROVE" || sendEmail?.isPending}
                isLoading={loadingFor === "REJECT"}
                onClick={() => {
                  setLoadingFor("REJECT");
                  dispatch(
                    updateVisitor(
                      id,
                      {
                        status: "REJECTED",
                        remarks,
                        kycInfo,
                        kycHistory: [
                          ...visitorInfo.kycHistory,
                          {
                            action: "Rejected",
                            name: user?.name,
                            status: "Rejected",
                            description: remarks,
                            createdAt: new Date()?.toString(),
                          },
                        ],
                      },
                      () => {
                        toast({
                          description: "Visitor KYC Rejected",
                          status: "success",
                          duration: 3000,
                        });
                        setLoadingFor("");
                        setRemarks("");
                      }
                    )
                  );
                  sendEmail?.mutateAsync({
                    from: "noreply@yess.com",
                    to: [visitorInfo?.personalInfo?.email],
                    subject: `KYC Update!`,
                    html: `
                        <div>
                        <p>Your KYC has been rejected. Please check your profile for more details.</p>                        
                        </div>
                      `,
                    body: "",
                  });
                }}
              >
                Reject
              </Button>
            </Flex>
          </Flex>
          <Box w="1px" bg="#f2f2f2" />
          <Flex flexDir="column" gap="32px" w="100%">
            <CustomText
              text="Vetting History"
              size="16px"
              weight="700"
              color="yess.neutral700"
            />
            <Flex gap="16px">
              <Flex flexDir="column" align="center">
                {visitorInfo?.kycHistory?.map((_: any, index: number) => (
                  <>
                    <Image src={FilledCircle} alt="filled" zIndex="10" />
                    {index !== visitorInfo.kycHistory.length - 1 && (
                      <Box
                        bg={"yess.secondary_gold"}
                        borderColor="#C1C2C4"
                        h="100px"
                        w="1px"
                        mt="-10px"
                        zIndex="1"
                      />
                    )}
                  </>
                ))}
              </Flex>
              <Flex flexDir="column" gap="16px">
                {visitorInfo?.kycHistory?.map((history: any, index: number) => (
                  <Flex flexDir="column" gap="8px">
                    <CustomText
                      text={
                        moment(history?.createdAt).format(
                          "MMM DD, YYYY hh:mm A"
                        ) || ""
                      }
                      size="12px"
                      color="yess.secondary_gold"
                    />
                    <Flex gap="10px" align="center">
                      <CustomText
                        text={history?.action}
                        size="16px"
                        color="yess.neutral700"
                      />
                      <Circle size="4px" bg="#387DF5" />
                      <CustomText
                        text={`${history?.name}`}
                        size="12px"
                        color="yess.secondary_blue"
                      />
                      <Button
                        h="28px"
                        w="102px"
                        display="flex"
                        gap="8px"
                        justifyContent="center"
                        alignItems="center"
                        bg={
                          history?.status !== "Rejected" ? "#2EC19E" : "#ED3D4E"
                        }
                        borderRadius="8px"
                        fontSize="12px"
                        fontWeight="400"
                        color="#fff"
                        _hover={{
                          bg: "#ED3D4E",
                        }}
                      >
                        {history?.status}
                      </Button>
                    </Flex>
                    <CustomText
                      text={history?.description}
                      size="14px"
                      color="yess.neutral500"
                    />
                  </Flex>
                ))}

                <Flex
                  p="16px"
                  borderRadius="8px"
                  bg="#f2f2f2"
                  w="400px"
                  display={visitorInfo?.remarks ? "flex" : "none"}
                >
                  <CustomText
                    text={visitorInfo?.remarks || ""}
                    size="14px"
                    color="yess.neutral700"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default VisitorDetails;
