import { Flex } from "@chakra-ui/react";
import React from "react";
import IconCongrats from "../../svgs/IconCongrats";
import CustomText from "../../components/text/CustomText";
import CustomButton from "../../components/buttons/CustomButton";
import { useNavigate } from "react-router-dom";

function Congrats() {
  const navigate = useNavigate();
  return (
    <Flex flexDir="column" align="center" gap="56px">
      <IconCongrats />
      <Flex gap="16px" flexDir="column">
        <CustomText
          text="Congratulations!"
          size="24px"
          weight="700"
          color="yess.base_black"
          align="center"
        />
        <CustomText
          text="Organization has successfully been created."
          color="yess.neutral600"
          align="center"
        />
        <Flex justify="center" mt="8px">
          <CustomButton
            btnTitle="Done"
            bg="yess.secondary_gold"
            maxW="170px"
            onClick={() => {
              navigate("/organizations");
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Congrats;
