import { Box, Flex } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";

function DashboardLayout() {
  const navigate = useNavigate();

  const { uid } = useSelector((state: any) => state.user);

  if (!uid) {
    navigate("/auth/login");
  }

  return (
    <Flex maxH="100vh">
      <Sidebar />
      <Flex flexDir="column" w="100%">
        <Navbar />
        <Box bg="#F5F5F5" p="24px" overflowY="auto">
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardLayout;
