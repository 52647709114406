import { toast } from "react-toastify";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { auth, firestore } from "../../config/firebase";

export const setLoading = (val: boolean) => async (dispatch: any) => {
  dispatch({
    type: "AUTH_LOADING",
    payload: val,
  });
};

export const getLoginData = (id: any) => async (dispatch: any) => {
  onSnapshot(doc(firestore, "admins", id), (doc: any) => {
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { docId: doc.id, ...doc.data() },
    });
    dispatch(setLoading(false));
  });
};

export const registerUser =
  (creds: any, password: any) => async (dispatch: any) => {
    dispatch(setLoading(true));
    createUserWithEmailAndPassword(auth, creds.email, password)
      .then((data) => {
        try {
          setDoc(doc(firestore, "admins", data.user.uid), creds)
            .then((res) => {
              dispatch({
                type: "REGISTER_SUCCESS",
              });
              toast.success("Account Created Successfully");
              dispatch(setLoading(false));
            })
            .catch((error) => {
              dispatch({
                type: "REGISTER_FAIL",
              });
              toast.warning(error.message);
              dispatch(setLoading(false));
            });
        } catch (e: any) {
          dispatch(setLoading(false));
          toast.warning(e.response.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: "REGISTER_FAIL",
        });
        dispatch(setLoading(false));
        toast.warning(error.message);
      });
  };
export const registerSuccess = () => {
  return (dispatch: any) => {
    dispatch({
      type: "REGISTER_COMPLETE",
    });
  };
};

export const login = (creds: any, onSuccess: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    signInWithEmailAndPassword(auth, creds.email, creds.password)
      .then((data) => {
        try {
          dispatch(getLoginData(data.user.uid));
          localStorage.setItem("uid", data.user.uid);
          onSuccess();
        } catch (e: any) {
          dispatch({
            type: "LOGIN_FAIL",
          });
          dispatch(setLoading(false));
          toast.warning(e.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
        });
        dispatch(setLoading(false));
        toast.warning(error.message);
      });
  };
};

export const logout = () => {
  return (dispatch: any) => {
    signOut(auth)
      .then((data) => {
        dispatch({
          type: "LOGOUT_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
        });
        toast.warning(error.message);
      });
  };
};

export const getAllAdmins = () => async (dispatch: any) => {
  const q = collection(firestore, "admins");
  onSnapshot(q, (querySnapshot) => {
    const admins: any = [];
    querySnapshot.forEach((doc) => {
      admins.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_ADMINS",
      payload: admins,
    });
  });
};

export const deleteAdmin = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "admins", id)).then((res) => {
      return;
    });
  } catch (e) {}
};
