import React, { useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Divider, Flex, useToast } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import CustomButton from "../../components/buttons/CustomButton";
import Documents from "./Documents";
import SendInvite from "./SendInvite";
import Congrats from "./Congrats";
import { useNavigate } from "react-router-dom";
import OrganizationInfo from "./OrganizationInfo";
import AdminContact from "./AdminContact";
import Quota from "./Quota";
import { useDispatch, useSelector } from "react-redux";
import { addOrganization } from "../../redux/actions/organization.actions";

function CreateOrganization() {
  const navigate = useNavigate();
  const toast = useToast();

  const dispatch: any = useDispatch();
  const { orgLoader } = useSelector((state: any) => state.organization);

  const [activeStep, setActiveStep] = useState(1);
  const [payload, setPayload] = useState({
    companyName: "",
    companyType: "",
    companyEmail: "",
    companyPhone: "",
    companyAddress: "",
    companyCity: "",
    companyZipCode: "",
    firstName: "",
    lastName: "",
    personalEmail: "",
    personalPhone: "",
    quota: "",
    iqamaID: "",
    passportNo: "",
    inviteEmail: "",
    invitePhone: "",
    inviteWhatsApp: "",
  });

  const checkActiveStep = (index: number) => {
    return activeStep === index + 1 || activeStep > index;
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return <OrganizationInfo payload={payload} setPayload={setPayload} />;
      case 2:
        return <AdminContact payload={payload} setPayload={setPayload} />;
      case 3:
        return <Quota payload={payload} setPayload={setPayload} />;
      case 4:
        return <Documents payload={payload} setPayload={setPayload} />;
      case 5:
        return <SendInvite payload={payload} setPayload={setPayload} />;
      case 6:
        return <Congrats />;
      default:
        return <OrganizationInfo payload={payload} setPayload={setPayload} />;
    }
  };

  const handleSaveOrg = () => {
    const newOrg = {
      companyInfo: {
        name: payload.companyName,
        country: payload.companyCity,
        icon: "",
      },
      assignedAdmin: {
        name: payload.firstName + " " + payload.lastName,
        email: payload.personalEmail,
        position: "Admin",
        img: "",
      },
      quota: payload.quota,
      documents: {
        iqamaID: payload.iqamaID,
        passportNo: payload.passportNo,
      },
      invite: {
        email: payload.inviteEmail,
        phone: payload.invitePhone,
        whatsApp: payload.inviteWhatsApp,
      },
      type: payload.companyType,
      status: "Pending",
    };
    dispatch(addOrganization(newOrg));
  };

  return (
    <CustomCard>
      <Flex flexDir="column" gap="35px">
        <CustomText
          text="Create Exhibitors"
          size="24px"
          weight="700"
          color="yess.base_black"
        />
        <Flex align="center">
          {STEPS.map((step, index) => (
            <Flex align="center" gap="12px" key={index}>
              <Flex align="center" gap="12px">
                <Flex
                  align="center"
                  justify="center"
                  minH="32px"
                  minW="32px"
                  borderRadius="999px"
                  bg={
                    checkActiveStep(index) ? "yess.secondary_gold" : "#221F1F"
                  }
                >
                  <CustomText
                    text={index + 1}
                    size="12px"
                    color={
                      checkActiveStep(index)
                        ? "yess.base_black"
                        : "yess.neutral300"
                    }
                  />
                </Flex>
                <CustomText
                  text={step.title}
                  wrap="nowrap"
                  color={
                    checkActiveStep(index)
                      ? "yess.base_black"
                      : "yess.neutral600"
                  }
                />
              </Flex>
              <Divider
                maxW="110px"
                minW="70px"
                opacity="1"
                h="1px"
                border="none"
                display={index === STEPS.length - 1 ? "none" : "block"}
                me="16px"
                bg={
                  checkActiveStep(index)
                    ? "yess.secondary_gold"
                    : "yess.neutral600"
                }
              />
            </Flex>
          ))}
        </Flex>
        {renderSteps()}
        <Flex
          borderTop="1px solid #F2F2F2"
          pt="16px"
          justify="end"
          gap="10px"
          mt="20px"
          display={activeStep === 6 ? "none" : "flex"}
        >
          <CustomButton
            btnTitle="Back"
            maxW="160px"
            bg="yess.neutral900"
            onClick={() => {
              if (activeStep === 1) {
                navigate("/organizations");
              } else {
                setActiveStep(activeStep - 1);
              }
            }}
          />
          <CustomButton
            btnTitle="Next"
            maxW="160px"
            bg="yess.secondary_gold"
            isLoading={orgLoader}
            onClick={() => {
              const stepValidation = [
                () =>
                  payload.companyName &&
                  payload.companyEmail &&
                  payload.companyPhone &&
                  payload.companyAddress &&
                  payload.companyCity &&
                  payload.companyZipCode,
                () =>
                  payload.firstName &&
                  payload.lastName &&
                  payload.personalEmail &&
                  payload.personalPhone,
                () => payload.quota,
                () => payload.iqamaID && payload.passportNo,
                () => payload.inviteEmail || payload.invitePhone,
              ];
              const canProceed =
                activeStep <= stepValidation.length
                  ? stepValidation[activeStep - 1]()
                  : true;

              if (canProceed) {
                if (activeStep === 5) {
                  handleSaveOrg();
                }
                setActiveStep(activeStep + 1);
              } else {
                toast({
                  description: "Please fill all the required fields",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
          />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

const STEPS = [
  {
    title: "Organization Info",
  },
  {
    title: "Assigned Admin Contact",
  },
  {
    title: "Quota",
  },
  {
    title: "Documents",
  },
  {
    title: "Send Invite",
  },
];

export default CreateOrganization;
