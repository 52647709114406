const initState = {
  eventLoading: false,
  events: [],
};
const eventReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "FETCH_EVENTS":
      return {
        ...state,
        events: action.payload,
      };
    case "EVENT_LOADING":
      return {
        ...state,
        eventLoading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default eventReducer;
