import React, { useEffect, useRef, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Box,
  Checkbox,
  Circle,
  Collapse,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Input,
  useToast,
} from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RotateIcon from "../../svgs/RotateIcon";
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconPlus,
} from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import CustomButton from "../../components/buttons/CustomButton";
import ImportIcon from "../../svgs/ImportIcon";
import ExhibitorsTable from "./ExhibitorsTable";
import IconUsers from "../../svgs/IconUsers";
import CountriesIcon from "../../svgs/CountriesIcon";
import IconSalesForce from "../../svgs/IconSalesForce";
import IconCSV from "../../svgs/IconCSV";
import { useNavigate } from "react-router-dom";
import IconChevDown from "../../svgs/IconChevDown";
import FormInput from "../../components/form/FormInput";
import IconCalendar from "../../svgs/IconCalendar";
import TotalExhibitorsChart from "../../components/charts/TotalExhibitorsChart";
import ReactMapChart from "../../components/charts/ReactMapChart";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import Papa from "papaparse";
import { useSendEmail } from "../../utils/wds.api";
import { useDispatch, useSelector } from "react-redux";
import {
  addExhibitor,
  getAllExhibitors,
} from "../../redux/actions/exhibitors.actions";
import {
  getBadgeTypes,
  getCountries,
  getTypes,
} from "../../redux/actions/general.actions";
import { getAllVisitors } from "../../redux/actions/visitors.actions";

function Exhibitors() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { exhibitors } = useSelector((state: any) => state.exhibitors);
  const { visitors } = useSelector((state: any) => state.visitors);

  const sendEmail = useSendEmail();
  const toast = useToast();

  const [selectedExhibitors, setSelectedExhibitors] = useState<any>([]);
  const [search, setSearch] = useState("");
  const csvRef = useRef<any>(null);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isTimerOpen, setIsTimerOpen] = useState(false);
  const [selectedTimer, setSelectedTimer] = useState("Every 10 mins");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState({
    personalInfo: true,
    companyInfo: false,
    location: false,
    assignedUser: false,
    filterByDate: false,
    invite: false,
  });

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result: any) => {
        const data = result.data;
        const dataMap = new Map();

        data.forEach((row: any) => {
          const id = row["id"];
          const personalInfo = {
            name: row["Contact Name"] || "",
            email: row["Contact Email"] || "",
            position: row["Contact Position"] || "",
            img: row["Contact Img"] || "",
          };

          if (!dataMap.has(id)) {
            dataMap.set(id, {
              id,
              companyInfo: {
                name: row["Company Name"] || "",
                country: row["Country"] || "",
                email: row["Company Email"] || "",
                phone: row["Company Phone"] || "",
                location: row["Company Address"] || "",
              },
              personalInfo: [personalInfo],
              location: {
                hall: parseInt(row["Hall"]) || 0,
                stand: parseInt(row["Stand"]) || 0,
                pavillion: parseInt(row["Pavillion"]) || 0,
                quota: parseInt(row["Quota"]) || 0,
              },
              assignedUsers: parseInt(row["Quota"]) || 0,
              utilizedBadges: parseInt(row["Utilized Badges"]) || 0,
              duplicate: false,
            });
          } else {
            // Mark as duplicate and add personal info
            const existingEntry = dataMap.get(id);
            existingEntry.duplicate = true;
            existingEntry.personalInfo.push(personalInfo);
          }
        });

        const newExhibitors = Array.from(dataMap.values());
        for (const obj of newExhibitors) {
          await dispatch(
            addExhibitor(obj, () => {
              csvRef.current.value = "";
            })
          );
        }
        // for (let i = 0; i < newExhibitors.length; i++) {
        //   console.log(newExhibitors[i], "------new exhibitors[i]");
        //   if (newExhibitors[i]?.companyInfo?.name) {
        //     dispatch(
        //       addExhibitor(newExhibitors[i], () => {
        //         // if (i === newExhibitors.length - 1) {
        //         //   csvRef.current.value = "";
        //         // }
        //       })
        //     );
        //   }
        // }
      },
    });
  };

  const handleBulkEmail = () => {
    const selectedEmails = exhibitors
      .filter((exhibitor: any) => selectedExhibitors.includes(exhibitor?.id))
      .flatMap((exhibitor: any) =>
        exhibitor?.companyInfo?.map((info: any) => info?.email)
      );

    if (selectedEmails.length > 0) {
      sendEmail
        .mutateAsync({
          to: selectedEmails,
          from: "noreply@yess.com",
          subject: `You're Invited to join WDS Event! Here's Your Temporary Login Information`,
          html: `
          <div style="font-family: Arial, sans-serif; line-height: 1.6;">
        <h2>We are excited to invite you to our upcoming event!</h2>
        <p>To access the event portal and confirm your participation, please log in with the following temporary credentials:</p>
        <p><strong>Email:</strong> Your Current Email</p>
        <p><strong>Password:</strong> Qwertyuiop@123</p>
        <p><strong>Exhibitor Panel:</strong>
        <a href="https://yess-exhibitors.pages.dev/auth/login">Click Here</a>
        </p>
        <p>We look forward to your participation!</p>
          </div>
        `,
          body: "",
        })
        .then((result) => {
          toast({
            description: "The invite has been sent successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setSelectedExhibitors([]);
        });
    }
  };

  const getTOP3CountriesByVisitors = () => {
    const countries = visitors.reduce((acc: any, visitor: any) => {
      if (acc[visitor.personalInfo?.personalCountry]) {
        acc[visitor.personalInfo?.personalCountry] += 1;
      } else {
        acc[visitor.personalInfo?.personalCountry] = 1;
      }
      return acc;
    }, {});
    const sortedCountries = Object.entries(countries).sort(
      (a: any, b: any) => b[1] - a[1]
    );
    return sortedCountries
      .slice(0, 3)
      .map(([name, count]: any) => ({ name, count }));
  };

  useEffect(() => {
    dispatch(getAllExhibitors());
    dispatch(getAllVisitors());
    dispatch(getCountries());
    dispatch(getBadgeTypes());
    dispatch(getTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomCard w="100%">
        <Flex align="center" justify="space-between">
          <Flex flexDir="column" w="100%">
            <CustomText text="Exhibitors" size="18px" color="yess.base_black" />
            <Flex gap="8px" align="center">
              <CustomText
                text="Last synced: Oct 21, 2024 10:30 AM"
                size="12px"
              />
              <Flex
                align="center"
                gap="6px"
                px="8px"
                h="24px"
                w="89px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Sync Data"
                  size="12px"
                  color="yess.secondary_blue"
                />
                <RotateIcon />
              </Flex>
              <Box pos="relative">
                <Flex
                  align="center"
                  gap="12px"
                  px="8px"
                  h="24px"
                  w="116px"
                  bg="yess.secondary_light_gray"
                  borderRadius="5px"
                  cursor="pointer"
                  justify="space-between"
                  onClick={() => setIsTimerOpen(!isTimerOpen)}
                >
                  <CustomText
                    text={selectedTimer}
                    size="12px"
                    color="yess.nuetral900"
                  />
                  <IconChevronDown size="12px" color="#000000" />
                </Flex>
                <OutsideClickHandler
                  isOpen={isTimerOpen}
                  onClose={() => setIsTimerOpen(false)}
                >
                  <Flex
                    flexDir="column"
                    bg="#f2f2f2"
                    p=" 6px 6px"
                    pos="absolute"
                    mt="5px"
                    borderRadius="6px"
                    zIndex="100"
                    gap="2px"
                  >
                    {["Every 10 mins", "Every 30 mins", "Every 1 hour"]?.map(
                      (timer, idx) => (
                        <>
                          <Flex
                            align="center"
                            gap="12px"
                            px="8px"
                            h="24px"
                            w="116px"
                            bg="yess.secondary_light_gray"
                            borderRadius="5px"
                            cursor="pointer"
                            justify="space-between"
                            onClick={() => {
                              setSelectedTimer(timer);
                              setIsTimerOpen(false);
                            }}
                          >
                            <CustomText
                              text={timer}
                              size="12px"
                              color="yess.nuetral900"
                            />
                            {selectedTimer === timer && (
                              <IconCheck size="12px" color="#000000" />
                            )}
                          </Flex>
                          <Divider bg="#f2f2f2" />
                        </>
                      )
                    )}
                  </Flex>
                </OutsideClickHandler>
              </Box>
              {selectedExhibitors?.length > 0 && (
                <Flex
                  align="center"
                  gap="6px"
                  px="8px"
                  h="24px"
                  bg="yess.secondary_gold"
                  borderRadius="5px"
                  onClick={handleBulkEmail}
                >
                  <CustomText
                    text={sendEmail?.isPending ? "Sending" : "Send Bulk Email"}
                    size="12px"
                    color="#fff"
                    cursor="pointer"
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex align="center" justify="end" gap="10px" w="100%">
            <Flex
              ps="6px"
              pe="16px"
              py="8px"
              height="48px"
              maxW="360px"
              w="100%"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.border"
              bg="yess.secondary_light_gray"
              align="center"
              gap="8px"
            >
              <Flex
                minH="28px"
                minW="28px"
                borderRadius="4px"
                bg="#fff"
                align="center"
                justify="center"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                <SortIcon />
              </Flex>
              <Input
                placeholder="Search Exhibitors..."
                color="yess.neutral900"
                fontSize="12px"
                fontWeight="400"
                pl="0px"
                className="aeonik-regular"
                _placeholder={{
                  color: "yess.neutral700",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                bg="transparent"
                border="none"
                _focus={{ border: "none" }}
                _hover={{ border: "none" }}
                _focusVisible={{ border: "none" }}
              />
              <SearchIcon />
            </Flex>
            <Box pos="relative">
              <CustomButton
                btnTitle="Import Exhibitor"
                size="12px"
                weight="400"
                color="yess.neutral800"
                h="48px"
                bg="transparent"
                radius="12px"
                border="1px solid"
                borderColor="yess.neutral100"
                maxW="145px"
                leftIco={<ImportIcon />}
                onClick={() => setIsImportOpen(!isImportOpen)}
              />
              {/* Import Dropdown */}
              <OutsideClickHandler
                isOpen={isImportOpen}
                onClose={() => setIsImportOpen(false)}
              >
                <Flex
                  p="12px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="yess.secondary_light_gray"
                  bg="#fff"
                  boxShadow="0px 2px 9.6px 0px rgba(0, 0, 0, 0.11)"
                  flexDir="column"
                  gap="8px"
                  w="145px"
                  pos="absolute"
                  display={isImportOpen ? "flex" : "none"}
                >
                  <Flex h="28px" align="center" gap="8px" cursor="pointer">
                    <IconSalesForce />
                    <CustomText
                      text="Via SalesForce"
                      size="12px"
                      color="yess.neutral700"
                    />
                  </Flex>
                  <Divider color="yess.secondary_light_gray" />
                  <Flex
                    h="28px"
                    align="center"
                    gap="8px"
                    cursor="pointer"
                    onClick={() => {
                      csvRef.current.click();
                      setIsImportOpen(false);
                    }}
                  >
                    <IconCSV />
                    <CustomText
                      text="Import CSV"
                      size="12px"
                      cusror="pointer"
                      color="yess.neutral700"
                    />
                  </Flex>
                </Flex>
              </OutsideClickHandler>
              <input
                ref={csvRef}
                type="file"
                hidden
                accept=".csv"
                onChange={handleFileChange}
              />
            </Box>
            <CustomButton
              btnTitle="Create Exhibitor"
              size="12px"
              weight="400"
              color="#fff"
              h="48px"
              bg="yess.secondary_gold"
              radius="12px"
              border="1px solid"
              borderColor="yess.secondary_gold"
              maxW="145px"
              leftIco={<IconPlus color="#fff" size="14px" />}
              onClick={() => navigate("/exhibitors/create-exhibitor")}
            />
          </Flex>
        </Flex>
        <ExhibitorsTable
          exhibitors={exhibitors}
          selected={selectedExhibitors}
          setSelected={setSelectedExhibitors}
          search={search}
        />
      </CustomCard>
      <Flex align="center" mt="24px" gap="24px">
        <CustomCard minH="330px" maxW="343px">
          <Flex justify="space-between" gap="24px" flexDir="column">
            <Flex flexDir="column" gap="24px">
              <Flex align="center" gap="16px">
                <Flex
                  bg="yess.secondary_light_gray"
                  borderRadius="8px"
                  align="center"
                  justify="center"
                  minH="40px"
                  minW="40px"
                >
                  <IconUsers />
                </Flex>
                <CustomText
                  text="Total Exhibitors"
                  color="yess.base_black"
                  size="18px"
                />
              </Flex>{" "}
              <CustomText
                text={Number(exhibitors?.length || 0)?.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}
                size="40px"
                color="yess.base_black"
              />
            </Flex>
            <TotalExhibitorsChart />
          </Flex>
        </CustomCard>
        <CustomCard minH="330px">
          <Flex justify="space-between" gap="24px" align="center">
            <Flex
              justify="space-between"
              flexDir="column"
              gap="24px"
              maxW="280px"
              minH="282px"
            >
              <Flex flexDir="column" gap="24px" w="100%">
                <Flex align="center" gap="16px">
                  <Flex
                    bg="yess.secondary_light_gray"
                    borderRadius="8px"
                    align="center"
                    justify="center"
                    minH="40px"
                    minW="40px"
                  >
                    <CountriesIcon />
                  </Flex>
                  <CustomText
                    text="Countries Participation"
                    color="yess.base_black"
                    size="18px"
                  />
                </Flex>{" "}
                <Flex justify="space-between" align="center">
                  <CustomText
                    text={visitors?.length || 0}
                    size="40px"
                    color="yess.base_black"
                  />
                  <Flex
                    w="87px"
                    h="20px"
                    align="center"
                    justify="center"
                    bg="#EBF3F1"
                    borderRadius="999px"
                  >
                    <CustomText
                      text="18%  Increased"
                      size="10px"
                      color="#2EC19E"
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="12px">
                {getTOP3CountriesByVisitors()?.map(
                  (country: any, index: number) => (
                    <Flex
                      key={index}
                      align="center"
                      justify="space-between"
                      gap="8px"
                    >
                      <Flex align="center" gap="8px">
                        <Circle bg="yess.neutral100" size="10px" />

                        <CustomText
                          text={country.name}
                          size="14px"
                          color="yess.neutral700"
                        />
                      </Flex>
                      <CustomText
                        text={country.count}
                        size="14px"
                        color="yess.base_black"
                      />
                    </Flex>
                  )
                )}
              </Flex>
            </Flex>
            <Box h="212px" w="1px" bg="yess.secondary_light_gray" />
            <Box mx="auto">
              <ReactMapChart />
            </Box>{" "}
          </Flex>
        </CustomCard>
      </Flex>
      <Drawer
        isOpen={isFilterOpen}
        onClose={() => {
          setIsFilterOpen(false);
        }}
      >
        <DrawerOverlay />
        <DrawerContent maxW="480px" p="24px" overflowY="auto">
          <Flex
            justify="space-between"
            flexDir="column"
            h="100%"
            gap="24px"
            pb="24px"
          >
            <Box>
              <CustomText
                text="Filters"
                size="24px"
                weight="700"
                color="yess.base_black"
              />
              <CustomText
                text="Add filter for more precise search."
                color="yess.neutral600"
                mt="12px"
              />
              <Divider my="24px" bg="#F2F2F2" w="400px" mx="auto" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() =>
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    personalInfo: !isCollapseOpen.personalInfo,
                  })
                }
              >
                <CustomText text="Personal Info" color="yess.base_black" />
                {isCollapseOpen.personalInfo ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}
              </Flex>
              <Collapse in={isCollapseOpen?.personalInfo} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Nationality" size="12px" />
                    <FormInput
                      ph="Select Nationality"
                      rightIco={<IconChevDown />}
                    />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="City" size="12px" />
                    <FormInput ph="Select City" rightIco={<IconChevDown />} />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Age" size="12px" />
                    <Flex align="center" gap="16px">
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Min"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Max"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    companyInfo: !isCollapseOpen.companyInfo,
                  });
                }}
              >
                <CustomText text="Company Info" color="yess.base_black" />
                {isCollapseOpen.companyInfo ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.companyInfo} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Country" size="12px" />
                    <FormInput
                      ph="Select Country"
                      rightIco={<IconChevDown />}
                    />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="City" size="12px" />
                    <FormInput ph="Select City" rightIco={<IconChevDown />} />
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    location: !isCollapseOpen.location,
                  });
                }}
              >
                <CustomText text="Event Location" color="yess.base_black" />
                {isCollapseOpen.location ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.location} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Hall #" size="12px" />
                    <FormInput ph="Select Hall #" />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Stand #" size="12px" />
                    <FormInput ph="Select Stand #" />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Pavilion #" size="12px" />
                    <FormInput ph="Select Pavilion #" />
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    assignedUser: !isCollapseOpen.assignedUser,
                  });
                }}
              >
                <CustomText text="Assigned Users" color="yess.base_black" />
                {isCollapseOpen.assignedUser ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.assignedUser} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="User Range" size="12px" />
                    <Flex align="center" gap="16px">
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Min"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Max"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    filterByDate: !isCollapseOpen.filterByDate,
                  });
                }}
              >
                <CustomText
                  text="Filter by Date (Creation)"
                  color="yess.base_black"
                />
                {isCollapseOpen.filterByDate ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.filterByDate} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Date" size="12px" />
                    <FormInput
                      ph="22 Aug, 2024 - 28 Aug, 2024"
                      rightIco={<IconCalendar />}
                    />
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    invite: !isCollapseOpen.invite,
                  });
                }}
              >
                <CustomText text="Invite Status" color="yess.base_black" />
                {isCollapseOpen.invite ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.invite} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText
                      text="Select status of invitation"
                      size="12px"
                    />
                    <Flex justify="space-between" align="center">
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="All"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="Accepted"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="Pending"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="Sent"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
            </Box>
            <CustomButton
              btnTitle="Apply"
              maxW="100%"
              bg="yess.secondary_gold"
              onClick={() => {
                setIsFilterOpen(false);
              }}
            />
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Exhibitors;
