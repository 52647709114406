import {
  Avatar,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import IconTrash from "../../svgs/IconTrash";
import { useDeleteUser } from "../../utils/wds.api";
import { useDispatch } from "react-redux";
import { deleteAdmin } from "../../redux/actions/auth.actions";

function AdminTable({ admins }: any) {
  const dispatch: any = useDispatch();

  const toast = useToast();

  const [loadingFor, setLoadingFor] = React.useState("");
  const deleteUser = useDeleteUser();
  return (
    <>
      <Flex mt="16px" gap="20px" flexDir="column">
        <TableContainer overflowY="auto">
          <Table>
            <Thead>
              <Tr>
                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopLeftRadius="12px"
                >
                  <CustomText
                    text="Admin Info"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Permissions & Roles"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopRightRadius="12px"
                >
                  <CustomText
                    text="Action"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {admins?.map((admin: any, idx: number) => (
                <Tr key={idx}>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 10px" w="200px">
                    <Flex gap="10px" py="10px" align="center">
                      <Avatar src={admin?.profileImg} boxSize="32px" />
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text={admin?.name}
                          color="yess.neutral1000"
                          size="14px"
                        />
                        <CustomText
                          text={admin?.email}
                          color="yess.neutral500"
                          size="10px"
                        />
                      </Flex>
                    </Flex>
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 10px">
                    <Flex gap="4px" align="center">
                      {admin?.permissions?.map(
                        (permission: string, idx: number) => (
                          <Flex
                            align="center"
                            justify="center"
                            bg="#F2F2F2"
                            borderRadius="12px"
                            px="16px"
                            py="6px"
                          >
                            <CustomText
                              text={permission}
                              size="12px"
                              color="yess.neutral800"
                            />
                          </Flex>
                        )
                      )}
                    </Flex>
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    borderRight="1px solid #f2f2f2"
                    p="16px 10px"
                    width="160px"
                  >
                    <Flex align="center" gap="6px">
                      <ActionButton icon={<EyeIcon />} />
                      <ActionButton
                        icon={<IconTrash />}
                        isLoading={
                          loadingFor === admin?.docId && deleteUser?.isPending
                        }
                        onClick={() => {
                          setLoadingFor(admin?.docId);
                          deleteUser
                            .mutateAsync(admin?.docId)
                            .then((result) => {
                              dispatch(deleteAdmin(admin?.docId));
                            })
                            .catch((err) => {
                              toast({
                                description: err?.message,
                                status: "error",
                                duration: 3000,
                                isClosable: true,
                              });
                            });
                        }}
                      />
                      <ActionButton
                        bg="yess.secondary_gold"
                        icon={<EditIcon />}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex align="center" gap="16px" justify="end">
          <CustomText
            text={`1-${admins?.length} of ${admins?.length}`}
            size="13px"
            color="yess.neutral300"
          />
          <Flex align="center" gap="2px">
            <PaginationButton icon={<ArrowLeft />} />
            <PaginationButton text="1" bg="yess.secondary_gold" />
            <PaginationButton icon={<ArrowRight />} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

const ActionButton = ({ icon, bg, onClick }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      cursor="pointer"
      onClick={onClick}
    >
      {icon}
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

export default AdminTable;
