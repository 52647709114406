import {
  Box,
  Divider,
  Flex,
  Image,
  Img,
  Modal,
  ModalContent,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import CustomText from "../../components/text/CustomText";
import MobileIcon from "../../svgs/MobileIcon";
import Hero1 from "../../assets/images/digital-signage/hero-1.png";
import Hero2 from "../../assets/images/digital-signage/hero-2.png";
import Hero3 from "../../assets/images/digital-signage/hero-3.png";
import Hero4 from "../../assets/images/digital-signage/hero-4.png";
import Hero5 from "../../assets/images/digital-signage/hero-5.png";
import Hero6 from "../../assets/images/digital-signage/hero-6.png";
import CustomButton from "../../components/buttons/CustomButton";
import {
  IconCheck,
  IconChevronDown,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import AdScreens from "./AdScreens";
import InfoScreens from "./InfoScreens";
import EventScreens from "./EventScreens";
import UploadIcon from "../../svgs/UploadIcon";
import DigitalSignageChart from "../../components/charts/DigitalSignageChart";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import { useCreateAd, useGetAds, useUploadMedia } from "../../utils/wds.api";

function DigitalSignage() {
  const uploadMedia = useUploadMedia();
  const createAd = useCreateAd();
  const { data: ads } = useGetAds();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const imgRef = useRef<any>(null);

  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState("Ad Screen");
  const [description, setDescription] = useState("");
  const [selectedImg, setSelectedImg] = useState("");

  const handleImgPick = (e: any) => {
    const file = e.target.files[0];
    let form = new FormData();
    form.append("file", file);
    uploadMedia?.mutateAsync(form).then((result) => {
      setSelectedImg(result?.url);
    });
  };

  const clearStates = () => {
    setDescription("");
    setSelectedScreen("Ad Screen");
    setSelectedImg("");
  };

  const handleAdCreation = () => {
    if (!selectedImg) {
      toast({
        description: "Please upload a file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!description) {
      toast({
        description: "Please enter a description",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    createAd
      .mutateAsync({
        adImageUrl: selectedImg,
        adDescription: description,
        adScreen: selectedScreen,
      })
      .then((result) => {
        toast({
          description: "Ad created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        clearStates();
        imgRef.current = null;
        onClose();
      })
      .catch((err) => {
        toast({
          description: "Ad creation failed",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <div>
      <Flex gap="24px" mb="24px" align="center">
        <CustomCard minH="342px">
          <Flex flexDir="column" gap="24px" justify="space-between">
            <Flex gap="16px" align="center">
              <Flex
                align="center"
                justify="center"
                h="40px"
                w="40px"
                bg={"yess.secondary_light_gray"}
                borderRadius="8px"
              >
                <MobileIcon />
              </Flex>
              <CustomText
                text="Total Digital Signage Screens"
                color="yess.base_black"
              />
            </Flex>{" "}
            <Flex justify="space-between" align="center">
              <DigitalSignageChart />
              <Flex flexDir="column" gap="24px">
                <Flex gap="8px" flexDir="column">
                  <CustomText text="Ads" size="14px" color="yess.neutral500" />
                  <Flex gap="6px" align="center">
                    <Box
                      bg="yess.secondary_gold"
                      height="12px"
                      width="12px"
                      mt="3px"
                    />
                    <CustomText
                      text="96"
                      size="16px"
                      color="yess.neutral1000"
                    />
                  </Flex>
                </Flex>
                <Flex gap="8px" flexDir="column">
                  <CustomText text="Info" size="14px" color="yess.neutral500" />
                  <Flex gap="6px" align="center">
                    <Box bg="#387DF5" height="12px" width="12px" mt="3px" />
                    <CustomText
                      text="96"
                      size="16px"
                      color="yess.neutral1000"
                    />
                  </Flex>
                </Flex>
                <Flex gap="8px" flexDir="column">
                  <CustomText
                    text="Event"
                    size="14px"
                    color="yess.neutral500"
                  />
                  <Flex gap="6px" align="center">
                    <Box bg="#2EC19E" height="12px" width="12px" mt="3px" />
                    <CustomText
                      text="96"
                      size="16px"
                      color="yess.neutral1000"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </CustomCard>
        <Flex flexDir="column" gap="16px">
          <Flex gap="16px">
            <Image src={Hero1} alt="hero1" maxW="324px" />
            <Image src={Hero2} alt="hero2" maxW="244px" />
            <Image src={Hero3} alt="hero3" maxW="234px" />
          </Flex>
          <Flex gap="16px">
            <Image src={Hero4} alt="hero4" maxW="158px" />
            <Image src={Hero5} alt="hero5" maxW="151px" />
            <Image src={Hero6} alt="hero6" maxW="493px" />
          </Flex>
        </Flex>
      </Flex>
      <CustomCard>
        <Flex justify="space-between" align="center">
          <CustomText
            text="Screens Distributions"
            size="18px"
            color="yess.base_black"
          />
          <CustomButton
            btnTitle="Add New Ad"
            size="12px"
            weight="400"
            color="#fff"
            h="48px"
            bg="yess.secondary_gold"
            radius="12px"
            border="1px solid"
            borderColor="yess.secondary_gold"
            maxW="124px"
            leftIco={<IconPlus color="#fff" size="14px" />}
            onClick={onOpen}
          />
        </Flex>
        <AdScreens
          ads={ads?.filter((ad: any) => ad.adScreen === "Ad Screen")}
        />
        <Divider my="12px" bg="#f2f2f2" />
        <InfoScreens
          ads={ads?.filter((ad: any) => ad.adScreen === "Info Screen")}
        />
        <Divider my="12px" bg="#f2f2f2" />
        <EventScreens
          ads={ads?.filter((ad: any) => ad.adScreen === "Event Screen")}
        />
      </CustomCard>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent maxW="600px">
          <Flex align="center" justify="space-between" p="24px" w="100%">
            <CustomText
              text="Add New Ad"
              size="20px"
              color="yess.base_black"
              mt="-2px"
            />
            <IconX
              size="20px"
              color="#000"
              onClick={() => {
                clearStates();
                onClose();
              }}
            />
          </Flex>
          <Divider bg="#CFCECE" />
          <Flex p="24px" flexDir="column" gap="24px">
            <Flex
              flexDir="column"
              gap="16px"
              align="center"
              p="32px 24px"
              borderRadius="16px"
              border="2px dashed"
              borderColor="yess.neutral500"
              bg="#F2F7FF"
            >
              {selectedImg ? (
                <Img src={selectedImg} objectFit="cover" />
              ) : (
                <>
                  <UploadIcon />
                  <CustomText text="Select Ad Files" color="yess.base_black" />
                  <CustomButton
                    btnTitle="Browse"
                    isLoading={uploadMedia?.isPending}
                    color="#fff"
                    bg="yess.secondary_gold"
                    maxW="111px"
                    height="36px"
                    size="14px"
                    weight="400"
                    onClick={() => {
                      imgRef.current.click();
                    }}
                  />
                </>
              )}
              <input type="file" hidden ref={imgRef} onChange={handleImgPick} />
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText
                text="Choose Screen"
                size="14px"
                color="yess.neutral400"
              />
              <Box pos="relative">
                <Flex
                  h="48px"
                  align="center"
                  justify="space-between"
                  px="20px"
                  borderRadius="8px"
                  bg="#F2F2F2"
                  cursor="pointer"
                  onClick={() => {
                    setIsScreenOpen(true);
                  }}
                >
                  <CustomText
                    text={selectedScreen}
                    size="14px"
                    color="yess.neutral600"
                  />
                  <IconChevronDown />
                </Flex>
                <OutsideClickHandler
                  isOpen={isScreenOpen}
                  onClose={() => {
                    setIsScreenOpen(false);
                  }}
                >
                  <Flex
                    flexDir="column"
                    gap="12px"
                    p="12px 24px"
                    bg="#f2f2f2"
                    borderRadius="12px"
                    pos="absolute"
                    mt="5px"
                    w="100%"
                    zIndex="1000"
                  >
                    {["Ad Screen", "Info Screen", "Event Screen"]?.map(
                      (type, idx) => (
                        <Flex
                          key={idx}
                          align="center"
                          cursor="pointer"
                          justify="space-between"
                          onClick={() => {
                            setIsScreenOpen(false);
                            setSelectedScreen(type);
                          }}
                        >
                          <CustomText
                            text={type}
                            size="12px"
                            color="yess.nuetral900"
                            cursor="pointer"
                          />
                          {selectedScreen === type && (
                            <IconCheck size="12px" color="#000000" />
                          )}
                        </Flex>
                      )
                    )}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Description" size="14px" />
              <Textarea
                placeholder="Type here..."
                borderRadius="12px"
                bg="yess.secondary_light_gray"
                fontSize="12px"
                px="24px"
                py="16px"
                fontWeight="400"
                color="yess.base_black"
                _placeholder={{
                  color: "yess.neutral600",
                }}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                border="none"
                _focus={{
                  border: "none",
                }}
                _focusVisible={{
                  border: "none",
                }}
                _hover={{
                  border: "none",
                }}
                className="aeonik-regular"
              />
            </Flex>
            <Flex
              pt="16px"
              borderTop="1px solid"
              borderColor="yess.neutral100"
              justify="end"
            >
              <CustomButton
                btnTitle="Upload"
                isLoading={createAd?.isPending}
                maxW="200px"
                bg="yess.secondary_gold"
                onClick={() => handleAdCreation()}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default DigitalSignage;
