import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, firestore } from "../../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";

export const getAllVisitors = () => async (dispatch: any) => {
  const q = collection(firestore, "visitors");
  onSnapshot(q, (querySnapshot) => {
    const visitors: any = [];
    querySnapshot.forEach((doc) => {
      visitors.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_VISITORS",
      payload: visitors,
    });
  });
};

export const getVisitorById = (id: any) => async (dispatch: any) => {
  const q = doc(firestore, "visitors", id);
  onSnapshot(q, (doc) => {
    dispatch({
      type: "FETCH_VISITOR",
      payload: { docId: doc.id, ...doc.data() },
    });
  });
};

export const deleteVisitor = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "visitors", id)).then((res) => {
      return;
    });
  } catch (e) {}
};

export const updateVisitor =
  (id: any, obj: any, onSuccess: any) => async (dispatch: any) => {
    try {
      updateDoc(doc(firestore, "visitors", id), obj).then((res) => {
        onSuccess();
        return;
      });
    } catch (e) {}
  };

export const inviteVisitor =
  (creds: any, password: any, onSuccess: any) => async (dispatch: any) => {
    createUserWithEmailAndPassword(auth, creds?.personalInfo?.email, password)
      .then((data) => {
        try {
          setDoc(doc(firestore, "visitors", data.user.uid), creds)
            .then((res) => {
              dispatch({
                type: "REGISTER_SUCCESS",
              });
              toast.success("Visitor Invited Successfully");
              onSuccess();
            })
            .catch((error) => {
              dispatch({
                type: "REGISTER_FAIL",
              });
              toast.warning(error.message);
            });
        } catch (e: any) {
          toast.warning(e.response.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: "REGISTER_FAIL",
        });
        toast.warning(error.message);
      });
  };

export const assignBadge =
  (id: any, payload: any, onSuccess: any) => async (dispatch: any) => {
    try {
      updateDoc(doc(firestore, "visitors", id), payload).then((res) => {
        onSuccess();
        toast.success("Badge Assigned Successfully");
        return;
      });
    } catch (e: any) {
      toast.warning(e.repsonse.message);
    }
  };
