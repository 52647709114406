import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TotalExhibitos from "./TotalExhibitos";
import TotalVisitors from "./TotalVisitors";
// import VisitroHeatMap from "./VisitroHeatMap";
import VisitorByType from "./VisitorByType";
import Comparison from "./Comparison";
import TopCountries from "./TopCountries";
import VisitorsCountry from "./VisitorsCountry";
import VettingStatus from "./VettingStatus";
import BadgesStatus from "./BadgesStats";
// import RevenueTracking from "./RevenueTracking";
import ExhibitorsByType from "./ExhibitorsByType";
import MostInteractedExhibitors from "./MostInteractedExhibitors";
import { useDispatch } from "react-redux";
import { getAllVisitors } from "../../redux/actions/visitors.actions";
import {
  getBadgeTypes,
  getCountries,
  getTypes,
} from "../../redux/actions/general.actions";

function Dashboard() {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getAllVisitors());
    dispatch(getCountries());
    dispatch(getBadgeTypes());
    dispatch(getTypes());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Flex gap="24px">
        <TotalExhibitos />
        <TotalVisitors />
        {/* <VisitroHeatMap /> */}
      </Flex>
      <Flex mt="24px" gap="24px">
        <VisitorByType />
        <Comparison />
      </Flex>
      <Flex mt="24px">
        <TopCountries />
      </Flex>
      <Flex gap="24px" mt="24px" align="center">
        <ExhibitorsByType />
        <MostInteractedExhibitors />
      </Flex>
      <Flex mt="24px" align="center" gap="24px">
        {/* <VisitorsAge /> */}
        <VisitorsCountry />
        <VettingStatus />
      </Flex>
      <Flex mt="24px" gap="24px">
        {/* <RevenueTracking /> */}
        <BadgesStatus />
      </Flex>
    </>
  );
}

export default Dashboard;
