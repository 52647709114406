import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";

export const getAllOrganizations = () => async (dispatch: any) => {
  const q = collection(firestore, "organizations");

  onSnapshot(q, (querySnapshot) => {
    const organizations: any = [];
    querySnapshot.forEach((doc) => {
      organizations.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_ORGANIZATIONS",
      payload: organizations,
    });
  });
};

export const setOrganizationLoading =
  (val: boolean) => async (dispatch: any) => {
    dispatch({
      type: "ORGANIZATION_LOADING",
      payload: val,
    });
  };

export const addOrganization = (obj: any) => async (dispatch: any) => {
  dispatch(setOrganizationLoading(true));
  try {
    addDoc(collection(firestore, "organizations"), obj).then((res) => {
      dispatch(setOrganizationLoading(false));
      return;
    });
  } catch (e) {
    dispatch(setOrganizationLoading(false));
  }
};

export const deleteOrg = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "organizations", id)).then((res) => {
      return;
    });
  } catch (e) {}
};
