const initState = {
  visitors: [],
  visitorInfo: null,
};
const visitorReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "FETCH_VISITORS":
      return {
        ...state,
        visitors: action.payload,
      };
    case "FETCH_VISITOR":
      return {
        ...state,
        visitorInfo: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default visitorReducer;
