import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import CustomPhoneInput from "../../components/form/PhoneInput";
import { useSelector } from "react-redux";
import IconChevDown from "../../svgs/IconChevDown";
import { IconCheck } from "@tabler/icons-react";

function PersonalInfo({ payload, setPayload }: any) {
  const [isCountryOpen, setIsCountryOpen] = React.useState(false);

  const { countries } = useSelector((state: any) => state.general);

  return (
    <Flex
      flexDir="column"
      gap="22px"
      maxW="666px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Personal Info" family="aeonik-bold" align="center" />
      <CustomText
        text="Enter Personal Info of the exhibitor"
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="First Name" size="12px" />
          <FormInput
            ph="Enter First Name"
            maxW="322px"
            value={payload?.firstName}
            onChange={(e: any) =>
              setPayload({ ...payload, firstName: e.target.value })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Last Name" size="12px" />
          <FormInput
            ph="Enter Last Name"
            maxW="322px"
            value={payload?.lastName}
            onChange={(e: any) =>
              setPayload({ ...payload, lastName: e.target.value })
            }
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Email" size="12px" />
          <FormInput
            ph="Enter Email"
            maxW="322px"
            value={payload?.personalEmail}
            onChange={(e: any) =>
              setPayload({
                ...payload,
                personalEmail: e.target.value,
                inviteEmail: e.target.value,
              })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="DOB" size="12px" />
          <FormInput
            ph="Enter DOB"
            maxW="322px"
            type="date"
            value={payload?.dateOfBirth}
            onChange={(e: any) =>
              setPayload({ ...payload, dateOfBirth: e.target.value })
            }
            // rightIco={<IconCalendar />}
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Nationality" size="12px" />
          <FormInput
            ph="Enter Nationality"
            maxW="322px"
            value={payload?.nationality}
            onChange={(e: any) =>
              setPayload({ ...payload, nationality: e.target.value })
            }
            // rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Phone Number" size="12px" />
          <CustomPhoneInput
            value={payload?.personalPhone}
            onChange={(e: any) => {
              setPayload({ ...payload, personalPhone: e, invitePhone: e });
            }}
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <Box pos="relative" w="100%">
            <Flex
              flexDir="column"
              gap="11px"
              w="100%"
              cursor="pointer"
              onClick={() => {
                setIsCountryOpen(!isCountryOpen);
              }}
            >
              <CustomText text="Country" size="12px" />
              <Flex
                px="24px"
                h="38px"
                align="center"
                justify="space-between"
                bg="#f2f2f2"
                borderRadius="8px"
              >
                <CustomText
                  text={payload?.personalCountry || "Select Country"}
                  size="14px"
                  color="yess.neutral_black"
                />
                <IconChevDown />
              </Flex>
            </Flex>
            <Flex
              flexDir="column"
              gap="12px"
              p="12px 24px"
              bg="#f2f2f2"
              borderRadius="12px"
              pos="absolute"
              mt="5px"
              w="100%"
              zIndex="1000"
              display={isCountryOpen ? "flex" : "none"}
              boxShadow={`0px 4px 4px rgba(0, 0, 0, 0.25)`}
            >
              {countries?.map((country: any, idx: number) => (
                <Flex
                  key={idx}
                  align="center"
                  cursor="pointer"
                  justify="space-between"
                  onClick={() => {
                    setIsCountryOpen(false);
                    setPayload({ ...payload, personalCountry: country?.name });
                  }}
                >
                  <CustomText
                    text={country?.name}
                    size="12px"
                    color="yess.nuetral900"
                    cursor="pointer"
                  />
                  {payload?.personalCountry === country?.name && (
                    <IconCheck size="12px" color="#000000" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="City" size="12px" />
          <FormInput
            ph="Select City"
            maxW="322px"
            value={payload?.personalCity}
            onChange={(e: any) =>
              setPayload({ ...payload, personalCity: e.target.value })
            }
            // rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Zip Code" size="12px" />
          <FormInput
            ph="Enter Zip Code"
            maxW="322px"
            value={payload?.personalZipCode}
            onChange={(e: any) =>
              setPayload({ ...payload, personalZipCode: e.target.value })
            }
          />
        </Flex>
      </Flex>

      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Address" size="12px" />
        <FormInput
          ph="Enter Address"
          value={payload?.personalAddress}
          onChange={(e: any) =>
            setPayload({ ...payload, personalAddress: e.target.value })
          }
        />
      </Flex>
    </Flex>
  );
}

export default PersonalInfo;
