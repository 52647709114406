import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ApexPieChart = ({ saudiVisitor, internationalVisitor }: any) => {
  const [options] = useState<any>({
    series: [internationalVisitor, saudiVisitor],
    chart: {
      width: 244,
      type: "pie",
    },
    labels: ["International Visitors", "Kingdom of Saudi Arabia Visitors"], // Two labels corresponding to the two series
    legend: {
      show: false,
    },
    colors: ["#D79A2B", "#387DF5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="pie"
        width={244}
      />
    </div>
  );
};

export default ApexPieChart;
