import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";
import { toast } from "react-toastify";

export const setEventLoading = (val: boolean) => async (dispatch: any) => {
  dispatch({
    type: "EVENT_LOADING",
    payload: val,
  });
};

export const getAllEvents = () => async (dispatch: any) => {
  const q = collection(firestore, "events");
  onSnapshot(q, (querySnapshot: any) => {
    const events: any = [];
    querySnapshot.forEach((doc: any) => {
      events.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_EVENTS",
      payload: events,
    });
  });
};

export const addEvent = (obj: any) => async (dispatch: any) => {
  dispatch(setEventLoading(true));
  try {
    addDoc(collection(firestore, "events"), obj).then((res) => {
      dispatch(setEventLoading(false));
      return;
    });
  } catch (e) {
    dispatch(setEventLoading(false));
  }
};

export const updateEvent = (id: any, obj: any) => async (dispatch: any) => {
  dispatch(setEventLoading(true));
  try {
    updateDoc(doc(firestore, "events", id), obj).then((res) => {
      dispatch(setEventLoading(false));
      toast.success("Event Updated Successfully");
      return;
    });
  } catch (e: any) {
    dispatch(setEventLoading(false));
    toast.warning(e.repsonse.message);
  }
};
