import React, { useEffect, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RotateIcon from "../../svgs/RotateIcon";
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import CustomButton from "../../components/buttons/CustomButton";
import IconChevDown from "../../svgs/IconChevDown";
import FormInput from "../../components/form/FormInput";
import IconCalendar from "../../svgs/IconCalendar";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import AdminTable from "./AdminTable";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdmins, registerUser } from "../../redux/actions/auth.actions";

const options: any = [
  {
    value: "Dashboard",
    label: "Dashboard",
  },
  {
    value: "Exhibitor",
    label: "Exhibitor",
  },
  {
    value: "Organizations",
    label: "Organizations",
  },
  {
    value: "Vetting",
    label: "Vetting",
  },
  {
    value: "Badges",
    label: "Badges",
  },
  {
    value: "Booth",
    label: "Booth",
  },
  {
    value: "Digital Signage",
    label: "Digital Signage",
  },
  {
    value: "Admin Management",
    label: "Admin Management",
  },
];

function AdminManagement() {
  const toast = useToast();

  const dispatch: any = useDispatch();
  const { admins } = useSelector((state: any) => state.user);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isTimerOpen, setIsTimerOpen] = useState(false);
  const [selectedTimer, setSelectedTimer] = useState("Every 10 mins");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState({
    personalInfo: true,
    companyInfo: false,
    location: false,
    assignedUser: false,
    filterByDate: false,
    invite: false,
  });
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    permissions: [],
  });

  const handleAdminCreation = () => {
    if (
      !payload?.name ||
      !payload?.email ||
      payload?.permissions?.length === 0
    ) {
      toast({
        title: "Fields are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newAdmins: any = {
      ...payload,
      permissions: payload.permissions.map(
        (permission: any) => permission.value
      ),
      icon: "https://via.placeholder.com/150", // Dummy image URL
    };

    dispatch(registerUser(newAdmins, "Qwertyuiop@123"));
    onClose();
    setPayload({
      name: "",
      email: "",
      permissions: [],
    });
  };

  useEffect(() => {
    dispatch(getAllAdmins());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomCard w="100%">
        <Flex align="center" justify="space-between">
          <Flex flexDir="column" w="100%">
            <CustomText
              text="Admin Management"
              size="18px"
              color="yess.base_black"
            />
            <Flex gap="8px" align="center">
              <CustomText
                text="Last synced: Oct 21, 2024 10:30 AM"
                size="12px"
              />
              <Flex
                align="center"
                gap="6px"
                px="8px"
                h="24px"
                w="89px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Sync Data"
                  size="12px"
                  color="yess.secondary_blue"
                />
                <RotateIcon />
              </Flex>
              <Box pos="relative">
                <Flex
                  align="center"
                  gap="12px"
                  px="8px"
                  h="24px"
                  w="116px"
                  bg="yess.secondary_light_gray"
                  borderRadius="5px"
                  cursor="pointer"
                  justify="space-between"
                  onClick={() => setIsTimerOpen(!isTimerOpen)}
                >
                  <CustomText
                    text={selectedTimer}
                    size="12px"
                    color="yess.nuetral900"
                  />
                  <IconChevronDown size="12px" color="#000000" />
                </Flex>
                <OutsideClickHandler
                  isOpen={isTimerOpen}
                  onClose={() => setIsTimerOpen(false)}
                >
                  <Flex
                    flexDir="column"
                    bg="#f2f2f2"
                    p=" 6px 6px"
                    pos="absolute"
                    mt="5px"
                    borderRadius="6px"
                    zIndex="100"
                    gap="2px"
                  >
                    {["Every 10 mins", "Every 30 mins", "Every 1 hour"]?.map(
                      (timer, idx) => (
                        <>
                          <Flex
                            align="center"
                            gap="12px"
                            px="8px"
                            h="24px"
                            w="116px"
                            bg="yess.secondary_light_gray"
                            borderRadius="5px"
                            cursor="pointer"
                            justify="space-between"
                            onClick={() => {
                              setSelectedTimer(timer);
                              setIsTimerOpen(false);
                            }}
                          >
                            <CustomText
                              text={timer}
                              size="12px"
                              color="yess.nuetral900"
                            />
                            {selectedTimer === timer && (
                              <IconCheck size="12px" color="#000000" />
                            )}
                          </Flex>
                          <Divider bg="#f2f2f2" />
                        </>
                      )
                    )}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
          </Flex>
          <Flex align="center" justify="end" gap="10px" w="100%">
            <Flex
              ps="6px"
              pe="16px"
              py="8px"
              height="48px"
              maxW="360px"
              w="100%"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.border"
              bg="yess.secondary_light_gray"
              align="center"
              gap="8px"
            >
              <Flex
                minH="28px"
                minW="28px"
                borderRadius="4px"
                bg="#fff"
                align="center"
                justify="center"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                <SortIcon />
              </Flex>
              <Input
                placeholder="Search "
                color="yess.neutral900"
                fontSize="12px"
                fontWeight="400"
                pl="0px"
                className="aeonik-regular"
                _placeholder={{
                  color: "yess.neutral700",
                }}
                bg="transparent"
                border="none"
                _focus={{ border: "none" }}
                _hover={{ border: "none" }}
                _focusVisible={{ border: "none" }}
              />
              <SearchIcon />
            </Flex>
            <CustomButton
              btnTitle="Create Admin"
              size="12px"
              weight="400"
              color="#fff"
              h="48px"
              bg="yess.secondary_gold"
              radius="12px"
              border="1px solid"
              borderColor="yess.secondary_gold"
              maxW="145px"
              leftIco={<IconPlus color="#fff" size="14px" />}
              onClick={() => onOpen()}
            />
          </Flex>
        </Flex>
        <AdminTable admins={admins} />
      </CustomCard>
      <Drawer
        isOpen={isFilterOpen}
        onClose={() => {
          setIsFilterOpen(false);
        }}
      >
        <DrawerOverlay />
        <DrawerContent maxW="480px" p="24px" overflowY="auto">
          <Flex
            justify="space-between"
            flexDir="column"
            h="100%"
            gap="24px"
            pb="24px"
          >
            <Box>
              <CustomText
                text="Filters"
                size="24px"
                weight="700"
                color="yess.base_black"
              />
              <CustomText
                text="Add filter for more precise search."
                color="yess.neutral600"
                mt="12px"
              />
              <Divider my="24px" bg="#F2F2F2" w="400px" mx="auto" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() =>
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    personalInfo: !isCollapseOpen.personalInfo,
                  })
                }
              >
                <CustomText text="Personal Info" color="yess.base_black" />
                {isCollapseOpen.personalInfo ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}
              </Flex>
              <Collapse in={isCollapseOpen?.personalInfo} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Nationality" size="12px" />
                    <FormInput
                      ph="Select Nationality"
                      rightIco={<IconChevDown />}
                    />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="City" size="12px" />
                    <FormInput ph="Select City" rightIco={<IconChevDown />} />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Age" size="12px" />
                    <Flex align="center" gap="16px">
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Min"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Max"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    companyInfo: !isCollapseOpen.companyInfo,
                  });
                }}
              >
                <CustomText text="Company Info" color="yess.base_black" />
                {isCollapseOpen.companyInfo ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.companyInfo} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Country" size="12px" />
                    <FormInput
                      ph="Select Country"
                      rightIco={<IconChevDown />}
                    />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="City" size="12px" />
                    <FormInput ph="Select City" rightIco={<IconChevDown />} />
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    location: !isCollapseOpen.location,
                  });
                }}
              >
                <CustomText text="Event Location" color="yess.base_black" />
                {isCollapseOpen.location ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.location} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Hall #" size="12px" />
                    <FormInput ph="Select Hall #" />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Stand #" size="12px" />
                    <FormInput ph="Select Stand #" />
                  </Flex>
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Pavilion #" size="12px" />
                    <FormInput ph="Select Pavilion #" />
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    assignedUser: !isCollapseOpen.assignedUser,
                  });
                }}
              >
                <CustomText text="Assigned Users" color="yess.base_black" />
                {isCollapseOpen.assignedUser ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.assignedUser} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="User Range" size="12px" />
                    <Flex align="center" gap="16px">
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Min"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                      <Flex
                        align="center"
                        h="38px"
                        gap="10px"
                        p="9px 10px"
                        borderRadius="8px"
                        border="1px solid #D0D5DD"
                        bg="yess.secondary_light_gray"
                      >
                        <CustomText
                          text="Max"
                          size="12px"
                          color="yess.neutral600"
                        />
                        <Input
                          type="number"
                          fontSize="12px"
                          color="yess.base_black"
                          bg="transparent"
                          border="none"
                          _hover={{
                            border: "none",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          _focusVisible={{
                            border: "none",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    filterByDate: !isCollapseOpen.filterByDate,
                  });
                }}
              >
                <CustomText
                  text="Filter by Date (Creation)"
                  color="yess.base_black"
                />
                {isCollapseOpen.filterByDate ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.filterByDate} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText text="Date" size="12px" />
                    <FormInput
                      ph="22 Aug, 2024 - 28 Aug, 2024"
                      rightIco={<IconCalendar />}
                    />
                  </Flex>
                </Flex>
              </Collapse>
              <Divider my="24px" bg="#C1C2C4" />
              <Flex
                align="center"
                cursor="pointer"
                gap="24px"
                justify="space-between"
                onClick={() => {
                  setIsCollapseOpen({
                    ...isCollapseOpen,
                    invite: !isCollapseOpen.invite,
                  });
                }}
              >
                <CustomText text="Invite Status" color="yess.base_black" />
                {isCollapseOpen.invite ? (
                  <IconChevronUp size="16px" color="#0B0A0A" />
                ) : (
                  <IconChevDown />
                )}{" "}
              </Flex>
              <Collapse in={isCollapseOpen?.invite} animateOpacity>
                <Flex mt="24px" gap="24px" flexDir="column">
                  <Flex flexDir="column" gap="11px">
                    <CustomText
                      text="Select status of invitation"
                      size="12px"
                    />
                    <Flex justify="space-between" align="center">
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="All"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="Accepted"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="Pending"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                      <Checkbox size="lg" gap="12px">
                        <CustomText
                          text="Sent"
                          size="14px"
                          color="yess.base_black"
                        />
                      </Checkbox>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
            </Box>
            <CustomButton
              btnTitle="Apply"
              maxW="100%"
              bg="yess.secondary_gold"
              onClick={() => {
                setIsFilterOpen(false);
              }}
            />
          </Flex>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent maxW="600px" borderRadius="16px">
          <Flex
            align="center"
            justify="space-between"
            p="24px"
            borderBottom="1px solid #CFCECE"
          >
            <CustomText
              text="Create New Admin"
              size="20px"
              color="yess.neutral_black"
            />
            <IconX
              onClick={() => {
                setPayload({
                  name: "",
                  email: "",
                  permissions: [],
                });
                onClose();
              }}
            />
          </Flex>
          <Flex flexDir="column" gap="24px" p="24px">
            <Flex flexDir="column" gap="12px">
              <CustomText text="Name" size="14px" />
              <FormInput
                h="48px"
                ph="Enter Name"
                value={payload?.name}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    name: e.target.value,
                  });
                }}
              />
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Email" size="14px" />
              <FormInput
                h="48px"
                ph="Enter Email"
                type="email"
                value={payload?.email}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    email: e.target.value,
                  });
                }}
              />
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Permissions & Roles" size="14px" />
              <Select
                options={options}
                isMulti
                value={payload?.permissions}
                onChange={(selectedOption: any) => {
                  setPayload({
                    ...payload,
                    permissions: selectedOption,
                  });
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#f2f2f2",
                    border: "none",
                    height: "48px",
                  }),
                }}
              />
            </Flex>
            <Flex justify="end">
              <CustomButton
                btnTitle="Create Admin"
                weight="400"
                bg="yess.secondary_gold"
                onClick={handleAdminCreation}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AdminManagement;
