import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Avatar, Box, Flex } from "@chakra-ui/react";
import CountriesIcon from "../../svgs/CountriesIcon";
import CustomText from "../../components/text/CustomText";
import SaudiFlag from "../../assets/images/flag-saudi.svg";
import QatarFlag from "../../assets/images/flag-qatar.svg";
import USFlag from "../../assets/images/flag-us.svg";
import UKFlag from "../../assets/images/flag-uk.svg";
import ChinaFlag from "../../assets/images/flag-china.svg";
import AustraliaFlag from "../../assets/images/flag-australia.svg";
import ReactMapChart from "../../components/charts/ReactMapChart";
import { useSelector } from "react-redux";

function TopCountries() {
  const { visitors } = useSelector((state: any) => state.visitors);
  const { countries } = useSelector((state: any) => state.general);

  const getCountryPercentage = (country: string) => {
    const countryVisitors = visitors?.filter(
      (visitor: any) => visitor?.personalInfo?.personalCountry === country
    );
    return (
      ((countryVisitors?.length / visitors?.length) * 100)?.toFixed(0) + "%"
    );
  };

  const getVisitorCountByCountry = (country: string) => {
    const countryVisitors = visitors?.filter(
      (visitor: any) => visitor?.personalInfo?.personalCountry === country
    );
    return countryVisitors?.length;
  };

  const getTop6Countries = () => {
    const countriesArray = countries?.map((country: any) => ({
      title: country?.name,
      value: getCountryPercentage(country.name),
      visitors: getVisitorCountByCountry(country.name),
      flag: country.flag,
    }));

    const flagMap: { [key: string]: string } = {
      "Saudi Arabia": SaudiFlag,
      Qatar: QatarFlag,
      USA: USFlag,
      "United Kingdom": UKFlag,
      China: ChinaFlag,
      Australia: AustraliaFlag,
    };

    countriesArray.forEach((country: any) => {
      country.flag = flagMap[country.title] || country.flag;
    });
    return countriesArray
      .sort((a: any, b: any) => b.visitors - a.visitors)
      .slice(0, 6);
  };

  return (
    <CustomCard w="100%">
      <Flex align="center" gap="16px">
        <Flex
          minH="40px"
          minW="40px"
          borderRadius="8px"
          bg="yess.secondary_light_gray"
          align="center"
          justify="center"
        >
          <CountriesIcon />
        </Flex>
        <Flex gap="4px" align="center">
          <CustomText text="Countries" color="yess.base_black" />
          <CustomText text="(Top 6)" color="yess.neutral400" />
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" gap="24px" mt="24px">
        <Box maxW="500px" w="100%">
          <Flex
            pb="8px"
            borderBottom="1px solid"
            borderColor="yess.border"
            align="center"
            justify="space-between"
          >
            <CustomText
              text="Total Visitors"
              size="17px"
              color="yess.neutral300"
            />
            <CustomText
              text={visitors?.length || 0}
              size="24px"
              color="yess.neutral1000"
            />
          </Flex>
          <Flex mt="16px" gap="12px" flexDir="column">
            {getTop6Countries()?.map((country: any, index: number) => (
              <Flex
                key={index}
                align="center"
                justify="space-between"
                gap="8px"
              >
                <Flex align="center" gap="8px">
                  <Avatar
                    src={country?.flag}
                    boxSize="16px"
                    name={country?.title}
                  />
                  {/* <Image src={country?.flag} boxSize="16px" /> */}
                  <CustomText
                    text={country?.title}
                    size="12px"
                    color="yess.base_black"
                  />
                </Flex>
                <Flex align="center" gap="8px">
                  <CustomText
                    text={country?.visitors}
                    size="12px"
                    color="yess.neutral600"
                  />
                  <CustomText
                    text={country?.value}
                    size="12px"
                    color="yess.secondary_blue"
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Box>
        <Box w="1px" bg="yess.secondary_light_gray" minH="100%" />
        <Box mx="auto">
          <ReactMapChart />
        </Box>{" "}
      </Flex>
    </CustomCard>
  );
}

export default TopCountries;
