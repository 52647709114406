const initState = {
  user: null,
  uid: localStorage.getItem("uid") || null,
  admins: [],
};

const authUser = (state = initState, action: any) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.payload.docId,
        user: { ...action.payload },
      };
    case "FETCH_ADMINS":
      return {
        ...state,
        admins: action.payload,
      };
    case "LOGIN_FAIL":
      localStorage.clear();
      return {
        ...state,
        uid: null,
        user: null,
      };
    case "LOGOUT_SUCCESS":
      localStorage.clear();
      return {
        ...state,
        uid: null,
        user: null,
      };

    default:
      return {
        ...state,
      };
  }
};
export default authUser;
