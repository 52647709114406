import {
  Avatar,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import IconTrash from "../../svgs/IconTrash";
import { useDispatch } from "react-redux";
import { deleteOrg } from "../../redux/actions/organization.actions";
import { useSendEmail } from "../../utils/wds.api";

function OrganizationTable({ organizations, search }: any) {
  const toast = useToast();

  const dispatch: any = useDispatch();

  const [loadingFor, setLoadingFor] = useState(null);

  const sendEmail = useSendEmail();

  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="300px"
                borderTopLeftRadius="12px"
              >
                <CustomText
                  text="Company Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="260px"
              >
                <CustomText
                  text="Country"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="136px"
              >
                <CustomText
                  text="Type"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="118px"
              >
                <CustomText
                  text="Vetting Status"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>

              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="148px"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {organizations
              ?.sort((a: any, b: any) => b.id - a.id)
              ?.filter((exhi: any) =>
                exhi.companyInfo.name
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              ?.map((exhi: any, idx: number) => (
                <Tr key={idx}>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 20px">
                    <Flex flexDir="column" gap="10px">
                      <Flex gap="10px" align="center">
                        <Avatar
                          boxSize="32px"
                          bg="yess.secondary_gold"
                          src={exhi.companyInfo?.icon}
                          name={exhi?.companyInfo?.name}
                        />
                        <Flex flexDir="column" gap="4px">
                          <CustomText
                            text={exhi?.companyInfo?.name}
                            color="yess.neutral1000"
                          />
                          <CustomText
                            text={exhi?.companyInfo?.country}
                            color="yess.neutral500"
                            size="12px"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 20px"
                    verticalAlign="center"
                  >
                    <CustomText
                      text={exhi?.companyInfo?.country}
                      color="yess.neutral800"
                      size="14px"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                    <CustomText
                      text={exhi?.type}
                      size="14px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                    <Badge status={exhi.status} />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    borderRight="1px solid #f2f2f2"
                    p="16px 20px"
                  >
                    <Flex align="center" gap="6px">
                      <ActionButton icon={<EyeIcon />} />
                      <ActionButton
                        icon={<MailIcon />}
                        isLoading={
                          loadingFor === exhi?.companyInfo?.email &&
                          sendEmail?.isPending
                        }
                        onClick={() => {
                          setLoadingFor(exhi?.companyInfo?.email);
                          sendEmail
                            .mutateAsync({
                              to: [exhi?.companyInfo?.email],
                              from: "noreply@yess.com",
                              subject: `Welcome to WDS!`,
                              html: `
                            <div>
                              <h1>Welcome to WDS</h1>
                              <p>Thank you for joining WDS. We are excited to have you on board.</p>
                              <p>Best Regards,</p>
                              <p>WDS Team</p>
    `,
                              body: "",
                            })
                            .then((result) => {
                              toast({
                                description: "Email has been sent successfully",
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                              });
                            });
                        }}
                      />
                      <ActionButton
                        // bg="yess.secondary_gold"

                        onClick={() => {
                          dispatch(deleteOrg(exhi?.docId));
                        }}
                        icon={<IconTrash />}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText
          text={`1-${organizations?.length || 0} of ${
            organizations?.length || 0
          }`}
          size="13px"
          color="yess.neutral300"
        />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" bg="yess.secondary_gold" />
          {/* <PaginationButton text="2" />
          <PaginationButton text="3" />
          <PaginationButton text="4" />
          <PaginationButton text="5" /> */}
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const ActionButton = ({ icon, bg, onClick, isLoading }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      onClick={onClick}
      cursor="pointer"
    >
      {isLoading ? <Spinner size="sm" color="#000" /> : icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status) {
      case "Approved":
        return "#F1FFFC";
      case "Rejected":
        return "#FFE9EB";
      case "Pending":
        return "#FFF9F1";
      case "Info Required":
        return "#F9F9F9";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case "Approved":
        return "#2EC19E";
      case "Rejected":
        return "#ED3D4E";
      case "Pending":
        return "#EDA145";
      case "Info Required":
        return "#4E4949";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="124px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

export default OrganizationTable;
