import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
// import IconChevDown from "../../svgs/IconChevDown";
import CustomPhoneInput from "../../components/form/PhoneInput";

function OrganizationInfo({ payload, setPayload }: any) {
  return (
    <Flex
      flexDir="column"
      gap="22px"
      maxW="666px"
      minH="521px"
      w="100%"
      mx="auto"
    >
      <CustomHeading
        text="Organization Info"
        family="aeonik-bold"
        align="center"
      />
      <CustomText
        text="Enter basic information of the organization"
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Organization Name" size="12px" />
          <FormInput
            ph="Enter Organization Name"
            maxW="322px"
            value={payload?.companyName}
            onChange={(e: any) =>
              setPayload({ ...payload, companyName: e.target.value })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Type" size="12px" />
          <FormInput
            ph="Enter Type"
            maxW="322px"
            value={payload?.companyType}
            onChange={(e: any) =>
              setPayload({ ...payload, companyType: e.target.value })
            }
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Organization Email" size="12px" />
          <FormInput
            ph="Enter Email"
            type="email"
            value={payload?.companyEmail}
            onChange={(e: any) =>
              setPayload({ ...payload, companyEmail: e.target.value })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Organization Phone Number" size="12px" />
          <CustomPhoneInput
            value={payload?.companyPhone}
            onChange={(e: any) => {
              setPayload({ ...payload, companyPhone: e });
            }}
          />
        </Flex>
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Address" size="12px" />
        <FormInput
          ph="Enter Address"
          value={payload?.companyAddress}
          onChange={(e: any) =>
            setPayload({ ...payload, companyAddress: e.target.value })
          }
        />
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="City" size="12px" />
          <FormInput
            ph="Enter City Name"
            maxW="322px"
            value={payload?.companyCity}
            onChange={(e: any) =>
              setPayload({ ...payload, companyCity: e.target.value })
            }
            // rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Zip Code" size="12px" />
          <FormInput
            ph="Enter Zip Code"
            maxW="322px"
            value={payload?.companyZipCode}
            onChange={(e: any) =>
              setPayload({ ...payload, companyZipCode: e.target.value })
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default OrganizationInfo;
