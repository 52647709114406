import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import DashboardLayout from "./layouts/DashboardLayout";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  return (
    <Routes>
      {routes.map((route, index) =>
        route?.isPrivate ? (
          <Route key={index} path="/" element={<DashboardLayout />}>
            <Route path={route.path} element={<route.component />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        ) : route?.isPublic ? (
          <Route key={index} path={route.path} element={<route.component />} />
        ) : (
          <Route key={index} path="/auth" element={<AuthLayout />}>
            <Route path={route.path} element={<route.component />} />
            <Route path="*" element={<Login />} />
          </Route>
        )
      )}
    </Routes>
  );
}

export default App;
