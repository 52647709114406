import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";

export const getAllPromos = () => async (dispatch: any) => {
  const q = collection(firestore, "promos");

  onSnapshot(q, (querySnapshot) => {
    const promos: any = [];
    querySnapshot.forEach((doc) => {
      promos.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_PROMOS",
      payload: promos,
    });
  });
};

export const setPromoLoading = (val: boolean) => async (dispatch: any) => {
  dispatch({
    type: "PROMO_LOADING",
    payload: val,
  });
};

export const addPromo = (obj: any) => async (dispatch: any) => {
  dispatch(setPromoLoading(true));
  try {
    addDoc(collection(firestore, "promos"), obj).then((res) => {
      dispatch(setPromoLoading(false));
      return;
    });
  } catch (e) {
    dispatch(setPromoLoading(false));
  }
};

export const deletePromo = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "promos", id)).then((res) => {
      return;
    });
  } catch (e) {}
};
