import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import IconTrash from "../../svgs/IconTrash";
import { useDispatch, useSelector } from "react-redux";
import { deletePromo } from "../../redux/actions/promo.actions";

function PromoTable({ promos }: any) {
  const dispatch: any = useDispatch();

  const { organizations } = useSelector((state: any) => state.organization);

  const getOrganizationName = (id: any) => {
    const org = organizations.find((org: any) => org.docId === id);
    return org?.companyInfo?.name;
  };

  return (
    <>
      <Flex mt="16px" gap="20px" flexDir="column">
        <TableContainer overflowY="auto">
          <Table>
            <Thead>
              <Tr>
                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopLeftRadius="12px"
                >
                  <CustomText
                    text="Name"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Discount"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Code"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Redeems"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Organization"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Status"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopRightRadius="12px"
                >
                  <CustomText
                    text="Action"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {promos
                ?.sort((a: any, b: any) => b?.id - a?.id)
                ?.map((promo: any, idx: number) => (
                  <Tr key={idx}>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px" w="200px">
                      <Flex gap="10px" py="10px" align="center">
                        <CustomText
                          text={promo?.name}
                          color="yess.neutral1000"
                          size="14px"
                        />
                      </Flex>
                    </Td>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Flex gap="10px" py="10px" align="center">
                        <CustomText
                          text={`${promo?.discount}%`}
                          color="yess.neutral1000"
                          size="14px"
                        />
                      </Flex>
                    </Td>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Flex gap="10px" py="10px" align="center">
                        <CustomText
                          text={`${promo?.code}`}
                          color="yess.neutral1000"
                          size="14px"
                        />
                      </Flex>
                    </Td>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Flex gap="10px" py="10px" align="center">
                        <CustomText
                          text={`${promo?.redeems}`}
                          color="yess.neutral1000"
                          size="14px"
                        />
                      </Flex>
                    </Td>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Flex gap="10px" py="10px" align="center">
                        <CustomText
                          text={`${getOrganizationName(promo?.organization)}`}
                          color="yess.neutral1000"
                          size="14px"
                        />
                      </Flex>
                    </Td>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Badge status={promo?.status} />
                    </Td>

                    <Td
                      borderLeft="1px solid #f2f2f2"
                      borderRight="1px solid #f2f2f2"
                      p="16px 10px"
                      width="160px"
                    >
                      <Flex align="center" gap="6px">
                        {/* <ActionButton icon={<EyeIcon />} /> */}
                        <ActionButton
                          icon={<IconTrash />}
                          onClick={() => {
                            dispatch(deletePromo(promo?.docId));
                          }}
                        />
                        {/* <ActionButton
                          bg="yess.secondary_gold"
                          icon={<EditIcon />}
                        /> */}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex align="center" gap="16px" justify="end">
          <CustomText
            text={`1-${promos?.length} of ${promos?.length}`}
            size="13px"
            color="yess.neutral300"
          />
          <Flex align="center" gap="2px">
            <PaginationButton icon={<ArrowLeft />} />
            <PaginationButton text="1" bg="yess.secondary_gold" />
            <PaginationButton icon={<ArrowRight />} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

const ActionButton = ({ icon, bg, onClick }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      cursor="pointer"
      onClick={onClick}
    >
      {icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status) {
      case "Active":
        return "#F1FFFC";
      case "Expired":
        return "#FFE9EB";
      case "Pending":
        return "#FFF9F1";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case "Active":
        return "#2EC19E";
      case "Expired":
        return "#ED3D4E";
      case "Pending":
        return "#EDA145";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="98px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

export default PromoTable;
