import { combineReducers } from "redux";
import exhibitorReducer from "./exhbitors.reducer";
import visitorReducer from "./visitors.reducers";
import organizationReducer from "./organizations.reducer";
import authReducer from "./auth.reducer";
import authUser from "./user.reducer";
import eventReducer from "./events.reducer";
import generalReducer from "./general.reducer";
import promoReducer from "./promo.reducer";

const appReducer = combineReducers({
  exhibitors: exhibitorReducer,
  visitors: visitorReducer,
  organization: organizationReducer,
  auth: authReducer,
  user: authUser,
  event: eventReducer,
  general: generalReducer,
  promo: promoReducer,
});
const rootReducer = (state: any, action: any) => {
  //@ts-ignore
  return appReducer(state, action);
};

export default rootReducer;
