import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import { IconMail, IconMapPin, IconPhone } from "@tabler/icons-react";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import { useDeleteUser, useSendEmail } from "../../utils/wds.api";
import { useDispatch } from "react-redux";
import IconTrash from "../../svgs/IconTrash";
import { deleteExhibitor } from "../../redux/actions/exhibitors.actions";

function ExhibitorsTable({ exhibitors, selected, setSelected, search }: any) {
  const dispatch: any = useDispatch();
  const sendEmail = useSendEmail();
  const deleteUser = useDeleteUser();

  const toast = useToast();

  const [loadingFor, setLoadingFor] = useState("");

  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                width="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopLeftRadius="12px"
                p="12px"
              >
                <Checkbox
                  isChecked={selected?.length === exhibitors?.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelected(exhibitors?.map((exhi: any) => exhi.id));
                    } else {
                      setSelected([]);
                    }
                  }}
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="290px"
              >
                <CustomText
                  text="Company Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="240px"
              >
                <CustomText
                  text="Personal Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="136px"
              >
                <CustomText
                  text="Location"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="118px"
              >
                <CustomText
                  text="Assigned Users"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="118px"
              >
                <CustomText
                  text="Utilized Badges"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="120px"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {exhibitors
              ?.sort((a: any, b: any) => b?.id - a?.id)
              ?.filter((exhi: any) => {
                if (search === "") {
                  return exhi;
                } else if (
                  exhi?.companyInfo?.name
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return exhi;
                }
                return false;
              })
              ?.map((exhi: any, idx: number) => (
                <Tr key={idx}>
                  <Td borderLeft="1px solid #F2F2F2" width="20px" p="12px">
                    <Checkbox
                      isChecked={selected?.includes(exhi.id) ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected([...selected, exhi.id]);
                        } else {
                          setSelected(
                            selected?.filter((id: any) => id !== exhi.id)
                          );
                        }
                      }}
                    />
                  </Td>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 16px">
                    <Flex flexDir="column" gap="10px">
                      <Flex gap="10px" align="center">
                        <Avatar
                          src={exhi.companyInfo?.icon}
                          name={exhi?.companyInfo?.name}
                          boxSize="32px"
                          bg="yess.secondary_gold"
                        />
                        <Flex flexDir="column" gap="4px">
                          <CustomText
                            text={exhi?.companyInfo?.name}
                            color="yess.neutral1000"
                          />
                          <CustomText
                            text={exhi?.companyInfo?.country}
                            color="yess.neutral500"
                            size="12px"
                          />
                        </Flex>
                      </Flex>
                      <Flex
                        flexDir="column"
                        pt="16px"
                        ps="10px"
                        gap="12px"
                        borderTop="1px solid #F2F2F2"
                        maxW="294px"
                      >
                        <Flex align="center" gap="10px">
                          <div>
                            <IconMail color="#4E4949" size="11px" />
                          </div>
                          <Box w="1px" h="14px" bg="#F2F2F2" />
                          <CustomText
                            text={exhi?.companyInfo?.email}
                            color="yess.neutral500"
                            size="12px"
                            lh="19px"
                          />
                        </Flex>
                        <Flex align="center" gap="10px">
                          <div>
                            <IconPhone color="#4E4949" size="11px" />
                          </div>
                          <Box w="1px" h="14px" bg="#F2F2F2" />
                          <CustomText
                            text={exhi?.companyInfo?.phone}
                            color="yess.neutral500"
                            size="12px"
                            lh="19px"
                          />
                        </Flex>
                        <Flex align="center" gap="10px">
                          <div>
                            <IconMapPin color="#4E4949" size="11px" />
                          </div>
                          <Box w="1px" h="14px" bg="#F2F2F2" />
                          <CustomText
                            text={exhi?.companyInfo?.location}
                            color="yess.neutral500"
                            size="12px"
                            lh="19px"
                            wrap="wrap"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="6px 12px"
                    verticalAlign="baseline"
                  >
                    {exhi?.personalInfo?.map((person: any, idx: number) => (
                      <Flex
                        key={idx}
                        gap="10px"
                        py="10px"
                        align="center"
                        borderBottom={
                          idx === exhi.personalInfo.length - 1
                            ? "none"
                            : "1px solid #F2F2F2"
                        }
                      >
                        <Avatar size="sm" src={person?.profileImg} />
                        <Flex flexDir="column" gap="4px">
                          <Flex align="center" gap="4px">
                            <CustomText
                              text={person?.name}
                              color="yess.neutral1000"
                            />
                            <CustomText
                              text={`(${person?.position})`}
                              color="yess.neutral500"
                              size="12px"
                            />
                          </Flex>
                          <CustomText
                            text={person?.email}
                            color="yess.neutral500"
                            size="12px"
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 12px"
                    verticalAlign="top"
                  >
                    <Flex align="center" gap="12px">
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text="Hall"
                          size="10px"
                          color="yess.neutral800"
                        />
                        <CustomText
                          text={"#" + exhi?.location?.hall}
                          size="14px"
                          color="yess.neutral500"
                        />
                      </Flex>
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text="Stand"
                          size="10px"
                          color="yess.neutral800"
                        />
                        <CustomText
                          text={"#" + exhi?.location?.stand}
                          size="14px"
                          color="yess.neutral500"
                        />
                      </Flex>
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text="Pavilion"
                          size="10px"
                          color="yess.neutral800"
                        />
                        <CustomText
                          text={"#" + exhi?.location?.pavillion}
                          size="14px"
                          color="yess.neutral500"
                        />
                      </Flex>
                    </Flex>
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 12px"
                    verticalAlign="top"
                  >
                    <CustomText
                      text={exhi?.assignedUsers}
                      size="14px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 12px"
                    verticalAlign="top"
                  >
                    <CustomText
                      text={exhi?.utilizedBadges}
                      size="14px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    borderRight="1px solid #f2f2f2"
                    p="16px 12px"
                    verticalAlign="top"
                  >
                    <Flex align="center" gap="6px">
                      <ActionButton
                        icon={<EyeIcon />}
                        onClick={() => {
                          window.open(
                            `https://yess-exhibitors.pages.dev?exhibitorId=${exhi?.docId}`,
                            // `http://localhost:3000?exhibitorId=${exhi?.docId}`,
                            "_blank"
                          );
                        }}
                      />
                      <ActionButton
                        icon={<MailIcon />}
                        isLoading={
                          loadingFor === exhi?.companyInfo?.email &&
                          sendEmail?.isPending
                        }
                        onClick={() => {
                          setLoadingFor(exhi?.companyInfo?.email);
                          sendEmail
                            .mutateAsync({
                              to: [exhi?.companyInfo?.email],
                              from: "noreply@yess.com",
                              subject: `You're Invited to join WDS Event! Here's Your Temporary Login Information`,
                              html: `
          <div style="font-family: Arial, sans-serif; line-height: 1.6;">
        <h2>We are excited to invite you to our upcoming event!</h2>
        <p>To access the event portal and confirm your participation, please log in with the following temporary credentials:</p>
        <p><strong>Email:</strong>${exhi?.companyInfo?.email}</p>
        <p><strong>Password:</strong> Qwertyuiop@123</p>
        <p><strong>Exhibitor Panel:</strong>
        <a href="https://yess-exhibitors.pages.dev/auth/login">Click Here</a>
        </p>
        <p>We look forward to your participation!</p>
          </div>
        `,
                              body: "",
                            })
                            .then((result) => {
                              toast({
                                description:
                                  "The invite has been sent successfully",
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                              });
                            });
                        }}
                      />
                      <ActionButton
                        // bg="yess.secondary_gold"
                        icon={<IconTrash />}
                        isLoading={
                          loadingFor === exhi?.docId && deleteUser?.isPending
                        }
                        onClick={() => {
                          setLoadingFor(exhi?.docId);
                          deleteUser
                            .mutateAsync(exhi?.docId)
                            .then((result) => {
                              dispatch(deleteExhibitor(exhi?.docId));
                            })
                            .catch((err) => {
                              toast({
                                description: err?.message,
                                status: "error",
                                duration: 3000,
                                isClosable: true,
                              });
                            });
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText
          text={`1-${exhibitors?.length || 0} of ${exhibitors?.length || 0}`}
          size="13px"
          color="yess.neutral300"
        />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" bg="yess.secondary_gold" />
          {/* <PaginationButton text="2" />
          <PaginationButton text="3" />
          <PaginationButton text="4" />
          <PaginationButton text="5" /> */}
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const ActionButton = ({ icon, bg, onClick, isLoading }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      cursor="pointer"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      onClick={onClick}
    >
      {isLoading ? <Spinner size="sm" color="#000" /> : icon}
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

export default ExhibitorsTable;
