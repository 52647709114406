import {
  Avatar,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconTrash from "../../svgs/IconTrash";
import { deleteVisitor } from "../../redux/actions/visitors.actions";
import { useDeleteUser, useSendEmail } from "../../utils/wds.api";

function VisitorTable({ visitors, search, filter }: any) {
  const toast = useToast();

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [loadingFor, setLoadingFor] = useState(null);

  const sendEmail = useSendEmail();
  const deleteUser = useDeleteUser();

  return (
    <>
      <Flex mt="16px" gap="20px" flexDir="column">
        <TableContainer overflowY="auto">
          <Table>
            <Thead>
              <Tr>
                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopLeftRadius="12px"
                >
                  <CustomText
                    text="Personal Info"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Company Info"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Badge No"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Badge Type"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Country"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Type"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Vetting Status"
                    size="12px"
                    align="center"
                    color="yess.neutral1000"
                  />
                </Th>

                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopRightRadius="12px"
                >
                  <CustomText
                    text="Action"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {visitors
                ?.sort((a: any, b: any) => {
                  return (
                    new Date(b?.user?.createdAt).getTime() -
                    new Date(a?.user?.createdAt).getTime()
                  );
                })
                ?.filter((exhi: any) => {
                  if (!search && filter === "All Visitors") return exhi;
                  const matchesSearch = search
                    ? exhi?.personalInfo?.name
                        ?.toLowerCase()
                        ?.includes(search.toLowerCase())
                    : true;
                  const matchesFilter =
                    filter !== "All Visitors"
                      ? exhi?.type?.toLowerCase() === filter?.toLowerCase()
                      : true;
                  return matchesSearch && matchesFilter;
                })
                ?.map((exhi: any, idx: number) => (
                  <Tr key={idx}>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Flex gap="10px" py="10px" align="center">
                        <Avatar
                          boxSize="32px"
                          src={exhi?.profileImg}
                          name={exhi?.personalInfo?.name}
                        />
                        <Flex flexDir="column" gap="4px">
                          <Flex align="center" gap="4px">
                            <CustomText
                              text={
                                exhi?.personalInfo?.name ||
                                exhi?.firstName + " " + exhi?.lastName
                              }
                              color="yess.neutral1000"
                              size="14px"
                            />
                            <CustomText
                              text={`(${
                                exhi?.personalInfo?.jobTitle ||
                                exhi?.companyInfo?.jobTitle ||
                                "--"
                              })`}
                              color="yess.neutral500"
                              size="10px"
                            />
                          </Flex>
                          <CustomText
                            text={
                              exhi?.personalInfo?.email || exhi?.personalEmail
                            }
                            color="yess.neutral500"
                            size="10px"
                          />
                        </Flex>
                      </Flex>
                    </Td>
                    <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                      <Flex flexDir="column" gap="10px">
                        <Flex gap="10px" align="center">
                          <Avatar
                            src={exhi.companyInfo?.icon}
                            name={
                              exhi?.companyInfo?.companyName ||
                              exhi?.companyInfo?.name ||
                              exhi?.companyName
                            }
                            boxSize="32px"
                            bg="yess.secondary_gold"
                          />
                          <Flex flexDir="column" gap="4px">
                            <CustomText
                              text={
                                exhi?.companyInfo?.companyName ||
                                exhi?.companyInfo?.name ||
                                exhi?.companyName
                              }
                              color="yess.neutral1000"
                              size="14px"
                            />
                            <CustomText
                              text={
                                exhi?.companyInfo?.country ||
                                exhi?.personalInfo?.personalCountry
                              }
                              color="yess.neutral500"
                              size="10px"
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td
                      borderLeft="1px solid #f2f2f2"
                      p="16px 10px"
                      verticalAlign="center"
                    >
                      <CustomText
                        text={exhi?.badge?.badgeNo || "--"}
                        color="yess.neutral800"
                        size="12px"
                        align="center"
                      />
                    </Td>
                    <Td
                      borderLeft="1px solid #f2f2f2"
                      p="16px 10px"
                      verticalAlign="center"
                    >
                      <CustomText
                        text={exhi?.badge?.type || "--"}
                        color="yess.neutral800"
                        size="12px"
                        align="center"
                      />
                    </Td>
                    <Td
                      borderLeft="1px solid #f2f2f2"
                      p="16px 10px"
                      verticalAlign="center"
                    >
                      <CustomText
                        text={exhi?.personalInfo?.personalCountry}
                        color="yess.neutral800"
                        size="12px"
                        align="center"
                      />
                    </Td>
                    <Td borderLeft="1px solid #f2f2f2" p="16px 10px">
                      <CustomText
                        text={exhi?.type || "--"}
                        size="12px"
                        color="yess.neutral800"
                        align="center"
                      />
                    </Td>
                    <Td borderLeft="1px solid #f2f2f2" p="16px 10px">
                      <Badge
                        status={exhi.status || exhi?.kycStatus || "Pending"}
                      />
                    </Td>
                    <Td
                      borderLeft="1px solid #f2f2f2"
                      borderRight="1px solid #f2f2f2"
                      p="16px 10px"
                    >
                      <Flex align="center" gap="6px">
                        <ActionButton
                          icon={<EyeIcon />}
                          onClick={() => {
                            navigate(`/visitor-details/${exhi?.docId}`);
                          }}
                        />
                        <ActionButton
                          icon={<MailIcon />}
                          isLoading={
                            loadingFor === exhi?.personalInfo?.email &&
                            sendEmail?.isPending
                          }
                          onClick={() => {
                            setLoadingFor(exhi?.personalInfo?.email);
                            sendEmail
                              .mutateAsync({
                                to: [exhi?.personalInfo?.email],
                                from: "noreply@yess.com",
                                subject: `Vetting Status: ${exhi?.status}`,
                                html: `
                              <h3>Dear ${exhi?.personalInfo?.name},</h3>
                              <p>Your vetting status is ${exhi?.status}</p>
                              <p>Thank you for your interest in WDS Expo</p>
      `,
                                body: "",
                              })
                              .then((result) => {
                                toast({
                                  description:
                                    "Email has been sent successfully",
                                  status: "success",
                                  duration: 3000,
                                  isClosable: true,
                                });
                              });
                          }}
                        />
                        <ActionButton
                          // bg="yess.secondary_gold"
                          icon={<IconTrash />}
                          isLoading={
                            loadingFor === exhi?.docId && deleteUser?.isPending
                          }
                          onClick={() => {
                            setLoadingFor(exhi?.docId);
                            deleteUser
                              .mutateAsync(exhi?.docId)
                              .then((result) => {
                                dispatch(deleteVisitor(exhi?.docId));
                              })
                              .catch((err) => {
                                toast({
                                  description: err?.message,
                                  status: "error",
                                  duration: 3000,
                                  isClosable: true,
                                });
                              });
                          }}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex align="center" gap="16px" justify="end">
          <CustomText
            text={`1-${visitors?.length || 0} of ${visitors?.length || 0}`}
            size="13px"
            color="yess.neutral300"
          />
          <Flex align="center" gap="2px">
            <PaginationButton icon={<ArrowLeft />} />
            <PaginationButton text="1" bg="yess.secondary_gold" />
            {/* <PaginationButton text="2" />
            <PaginationButton text="3" />
            <PaginationButton text="4" />
            <PaginationButton text="5" /> */}
            <PaginationButton icon={<ArrowRight />} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

const ActionButton = ({ icon, bg, onClick, isLoading }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      onClick={onClick}
      cursor="pointer"
    >
      {isLoading ? <Spinner size="sm" color="#000" /> : icon}
    </Flex>
  );
};
const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status?.toLowerCase()) {
      case "approved":
        return "#F1FFFC";
      case "rejected":
        return "#FFE9EB";
      case "pending":
        return "#FFF9F1";
      case "info required":
        return "#F9F9F9";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status?.toLowerCase()) {
      case "approced":
        return "#2EC19E";
      case "rejected":
        return "#ED3D4E";
      case "pending":
        return "#EDA145";
      case "info required":
        return "#4E4949";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="114px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText
        text={status}
        transform="uppercase"
        size="12px"
        color={getBorderColor()}
      />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

export default VisitorTable;
