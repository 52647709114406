import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import BgGradientLines from "../../assets/images/bg-lines.png";
import BlueLine from "../../svgs/BlueLine";
import { useSelector } from "react-redux";

function VisitorsCountry() {
  const { visitors } = useSelector((state: any) => state.visitors);
  const { countries } = useSelector((state: any) => state.general);

  const getCountryPercentage = (country: string) => {
    const countryVisitors = visitors.filter(
      (visitor: any) => visitor.personalInfo?.personalCountry === country
    );
    return ((countryVisitors.length / visitors.length) * 100).toFixed(0) + "%";
  };

  const getTop6Countries = () => {
    const countriesArray = countries.map((country: any) => ({
      title: country.name,
      value: getCountryPercentage(country.name),
    }));
    return countriesArray
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 6);
  };

  return (
    <CustomCard>
      <Flex gap="16px" align="center">
        <CustomText
          text="Visitors Country"
          wrap="nowrap"
          color="yess.base_black"
        />
        <Divider bg="#F2F2F2" />
      </Flex>
      <Flex flexDir="column" gap="8px" mt="24px">
        {getTop6Countries()?.map((country: any, index: number) => (
          <Flex key={index} gap="12px" align="flex-start">
            <Box maxW="47px" w="100%">
              <CustomText
                text={country.title}
                color="yess.neutral700"
                size="10px"
                align="end"
                mt="3px"
              />
            </Box>
            <Flex
              w="100%"
              h="24px"
              borderRadius="4px"
              background={`url(${BgGradientLines}), rgba(242, 242, 242, 0.75)`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              //   backgroundPosition="right"
            >
              <Flex
                p="4px"
                bg="#e9e9e9"
                w={country.value}
                h="24px"
                borderRadius="4px"
                zIndex="10"
              >
                <Box bg="#D79A2B" borderRadius="4px" w="100%" />
              </Flex>
              <Box mt="5px" ml="-2px" zIndex="1">
                <BlueLine />
              </Box>
            </Flex>
            <Box maxW="22px" w="100%">
              <CustomText
                text={country.value}
                color="yess.neutral700"
                size="10px"
                mt="3px"
              />
            </Box>
          </Flex>
        ))}
      </Flex>
    </CustomCard>
  );
}

export default VisitorsCountry;
