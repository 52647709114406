import AdminManagement from "../pages/admin-management";
import Analytics from "../pages/analytics";
import CreateEvent from "../pages/auth/CreateEvent";
import Login from "../pages/auth/Login";
import Badges from "../pages/badges";
import Booths from "../pages/booths";
import Dashboard from "../pages/dashboard/Dashboard";
import DigitalSignage from "../pages/digital-signage";
import Exhibitors from "../pages/exhibitors";
import CreateExhibitor from "../pages/exhibitors/CreateExhibitor";
import Organizations from "../pages/organizations";
import CreateOrganization from "../pages/organizations/CreateOrganization";
import PromoCode from "../pages/promo-code";
import Visitors from "../pages/visitors";
import VisitorDetails from "../pages/visitors/VisitorDetails";

export const routes = [
  {
    path: "login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "create-event",
    component: CreateEvent,
    isPublic: true,
  },

  {
    path: "/",
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/exhibitors",
    component: Exhibitors,
    isPrivate: true,
  },
  {
    path: "/exhibitors/create-exhibitor",
    component: CreateExhibitor,
    isPrivate: true,
  },
  {
    path: "/organizations",
    component: Organizations,
    isPrivate: true,
  },
  {
    path: "/organizations/create-organization",
    component: CreateOrganization,
    isPrivate: true,
  },
  {
    path: "/visitors",
    component: Visitors,
    isPrivate: true,
  },
  {
    path: "/visitor-details/:id",
    component: VisitorDetails,
    isPrivate: true,
  },
  {
    path: "/badges",
    component: Badges,
    isPrivate: true,
  },
  {
    path: "/booths",
    component: Booths,
    isPrivate: true,
  },
  {
    path: "/digital-signage",
    component: DigitalSignage,
    isPrivate: true,
  },
  {
    path: "/analytics",
    component: Analytics,
    isPrivate: true,
  },
  {
    path: "/admin-management",
    component: AdminManagement,
    isPrivate: true,
  },
  {
    path: "/promos",
    component: PromoCode,
    isPrivate: true,
  },
];
