import React, { useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import VisitorLineChart from "../../components/charts/VisitorLineChart";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";

function VisitorByType() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Today");

  return (
    <CustomCard minW="700px" w="100%">
      <Flex align="center" gap="16px">
        <CustomText
          text="Visitors By Type"
          color="yess.base_black"
          wrap="nowrap"
        />
        <Divider bg="#F2F2F2" mt="5px" />
        <Box pos="relative">
          <Flex
            justify="space-between"
            align="center"
            w="110px"
            h="32px"
            bg="yess.secondary_light_gray"
            borderRadius="8px"
            ps="12px"
            pe="6px"
            cursor="pointer"
            onClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            <CustomText
              text={selectedFilter}
              size="12px"
              color="yess.neutral600"
              cursor
            />
            <IconChevronDown color="#0B0A0A" size="16px" cursor="pointer" />
          </Flex>
          <OutsideClickHandler
            isOpen={isFilterOpen}
            onClose={() => {
              setIsFilterOpen(false);
            }}
          >
            <Flex
              w="110px"
              bg="yess.secondary_light_gray"
              borderRadius="8px"
              flexDir="column"
              cursor="pointer"
              pos="absolute"
              zIndex="10000"
              mt="5px"
            >
              {["Today", "Yesterday", "Last 7 Days", "Last 30 Days"]?.map(
                (filter, idx) => (
                  <>
                    <Flex
                      key={idx}
                      justify="space-between"
                      align="center"
                      h="32px"
                      ps="12px"
                      pe="6px"
                      cursor="pointer"
                      onClick={() => {
                        setSelectedFilter(filter);
                        setIsFilterOpen(false);
                      }}
                    >
                      <CustomText
                        text={filter}
                        size="12px"
                        color="yess.neutral600"
                        cursor="pointer"
                      />
                      {selectedFilter === filter && (
                        <IconCheck
                          color="#0B0A0A"
                          size="14px"
                          cursor="pointer"
                        />
                      )}
                    </Flex>
                    <Divider bg="#f2f2f2" />
                  </>
                )
              )}
            </Flex>
          </OutsideClickHandler>
        </Box>
      </Flex>
      <Box mt="24px">
        <VisitorLineChart />
      </Box>
    </CustomCard>
  );
}

export default VisitorByType;
