const initState = {
  exhibitors: [],
  exhibitorLoading: false,
};
const exhibitorReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "FETCH_EXHIBITORS":
      return {
        ...state,
        exhibitors: action.payload,
      };
    case "EXHIBITOR_LOADING":
      return {
        ...state,
        exhibitorLoading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default exhibitorReducer;
