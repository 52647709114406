const initState = {
  promos: [],
  promoLoader: false,
};
const promoReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "FETCH_PROMOS":
      return {
        ...state,
        promos: action.payload,
      };
    case "PROMO_LOADING":
      return {
        ...state,
        promoLoader: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default promoReducer;
