import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const VisitorLineChart = () => {
  const { visitors } = useSelector((state: any) => state.visitors);
  const { types } = useSelector((state: any) => state.general);

  const [series, setSeries] = useState([
    {
      name: "series1",
      data: [250, 240, 550, 490, 515, 320, 520, 480],
    },
  ]);

  const [options] = useState<any>({
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Organizer",
        "Exhibitor",
        "Ministry of defence",
        "Contractor",
        "Media",
        "Academic staff",
        "Student",
        "Staff",
      ],
    },

    grid: {
      borderColor: "#F2F2F2",
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  });

  useEffect(() => {
    const categories = types?.[0]?.types?.map((type: any) => type);

    const data = categories?.map((category: any) => {
      return visitors.filter((visitor: any) => visitor.type === category)
        ?.length;
    });

    setSeries([{ name: "series1", data }]);
  }, [visitors, types]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={218}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default VisitorLineChart;
