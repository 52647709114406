import React, { useEffect, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Box,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import {
  IconCheck,
  IconChevronDown,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import CustomButton from "../../components/buttons/CustomButton";
import RotateIcon from "../../svgs/RotateIcon";
import BadgeTable from "./BadgeTable";
import BadgeStats from "./BadgeStats";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import FormInput from "../../components/form/FormInput";
import { useDispatch, useSelector } from "react-redux";
import {
  assignBadge,
  getAllVisitors,
} from "../../redux/actions/visitors.actions";
import { getBadgeTypes } from "../../redux/actions/general.actions";
import { getAllOrganizations } from "../../redux/actions/organization.actions";
import Select from "react-select";

function Badges() {
  const toast = useToast();

  const dispatch: any = useDispatch();

  const { badgeTypes } = useSelector((state: any) => state.general);
  const { organizations } = useSelector((state: any) => state.organization);
  const { visitors } = useSelector((state: any) => state.visitors);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBulkOpen,
    onOpen: onBulkOpen,
    onClose: onBulkClose,
  } = useDisclosure();

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<any>([]);
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState("VIP");
  const [validFor, setValidFor] = useState("");
  const [org, setOrg] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [multiUsers, setMultiUsers] = useState<any>([]);

  const handleAssignBadge = () => {
    for (let i = 0; i < multiUsers.length; i++) {
      dispatch(
        assignBadge(
          multiUsers[i]?.value,
          {
            badge: {
              badgeNo: Math.floor(100000 + Math.random() * 900000).toString(),
              issueDate: new Date().toLocaleDateString(),
              type: selectedBadge,
              organization: org,
              validFor: validFor,
              status: "Active",
            },
            badgeAssigned: true,
          },
          () => {
            onClose();
            setSelected([]);
          }
        )
      );
    }
    setSelected([]);
    onBulkClose();
    clearStates();
  };

  const handleAssignSingleBadge = () => {
    dispatch(
      assignBadge(
        selectedUser?.value,
        {
          badge: {
            badgeNo: Math.floor(100000 + Math.random() * 900000).toString(),
            issueDate: new Date().toLocaleDateString(),
            type: selectedBadge,
            validFor: validFor,
            status: "Active",
            organization: org,
          },
          badgeAssigned: true,
        },
        () => {
          onClose();
          setSelected([]);
          setSelectedUser(null);
        }
      )
    );
    setSelected([]);
    onClose();
    clearStates();
  };

  const clearStates = () => {
    setSelectedBadge("VIP");
    setValidFor("");
    setOrg("");
    setSelectedUser(null);
    setMultiUsers([]);
  };

  useEffect(() => {
    dispatch(getAllVisitors());
    dispatch(getBadgeTypes());
    dispatch(getAllOrganizations());
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Box mb="24px">
        <BadgeStats />
      </Box>
      <CustomCard>
        <Flex align="center" justify="space-between">
          <Flex flexDir="column" w="100%">
            <CustomText text="Badge" size="18px" color="yess.base_black" />
            <Flex gap="8px" align="center">
              <CustomText
                text="Last synced: Sept 15, 2024 10:30 AM"
                size="12px"
              />
              <Flex
                align="center"
                gap="6px"
                px="8px"
                h="24px"
                w="89px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Sync Data"
                  size="12px"
                  color="yess.secondary_blue"
                />
                <RotateIcon />
              </Flex>
              <Flex
                align="center"
                gap="12px"
                px="8px"
                h="24px"
                w="112px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Every 10 mins"
                  size="12px"
                  color="yess.nuetral900"
                />
                <IconChevronDown size="12px" color="#000000" />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="10px" w="100%" justify="end">
            <Flex
              ps="6px"
              pe="16px"
              py="8px"
              height="48px"
              maxW="360px"
              w="100%"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.border"
              bg="yess.secondary_light_gray"
              align="center"
              gap="8px"
            >
              <Flex
                minH="28px"
                minW="28px"
                borderRadius="4px"
                bg="#fff"
                align="center"
                justify="center"
              >
                <SortIcon />
              </Flex>
              <Input
                placeholder="Search..."
                color="yess.neutral900"
                fontSize="12px"
                fontWeight="400"
                pl="0px"
                className="aeonik-regular"
                _placeholder={{
                  color: "yess.neutral700",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                bg="transparent"
                border="none"
                _focus={{ border: "none" }}
                _hover={{ border: "none" }}
                _focusVisible={{ border: "none" }}
              />
              <SearchIcon />
            </Flex>

            <CustomButton
              btnTitle="Create Bulk Badges"
              size="12px"
              weight="400"
              color="#fff"
              h="48px"
              bg="yess.secondary_gold"
              radius="12px"
              border="1px solid"
              borderColor="yess.secondary_gold"
              maxW="145px"
              leftIco={<IconPlus color="#fff" size="14px" />}
              onClick={onBulkOpen}
            />
            <CustomButton
              btnTitle="Create Single Badge"
              size="12px"
              weight="400"
              color="#fff"
              h="48px"
              bg="yess.secondary_gold"
              radius="12px"
              border="1px solid"
              borderColor="yess.secondary_gold"
              maxW="145px"
              leftIco={<IconPlus color="#fff" size="14px" />}
              onClick={onOpen}
            />
          </Flex>
        </Flex>
        <BadgeTable
          selected={selected}
          setSelected={setSelected}
          search={search}
        />
      </CustomCard>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="600px" borderRadius="16px">
          <Flex
            p="24px"
            align="center"
            justify="space-between"
            borderBottom="1px solid #CFCECE"
          >
            <CustomText
              text="Assign Single Badge"
              color="yess.base_black"
              size="20px"
            />
            <IconX
              color="#000"
              onClick={() => {
                onClose();
                clearStates();
              }}
            />
          </Flex>
          <Flex flexDir="column" gap="24px" p="24px">
            <Flex flexDir="column" gap="11px">
              <CustomText text="Type" size="14px" color="yess.neutral400" />
              <Box pos="relative">
                <Flex
                  h="48px"
                  align="center"
                  justify="space-between"
                  px="20px"
                  borderRadius="8px"
                  bg="#F2F2F2"
                  cursor="pointer"
                  onClick={() => {
                    setIsDropOpen(true);
                  }}
                >
                  <CustomText
                    text={selectedBadge}
                    size="14px"
                    color="yess.neutral600"
                  />
                  <IconChevronDown />
                </Flex>
                <OutsideClickHandler
                  isOpen={isDropOpen}
                  onClose={() => {
                    setIsDropOpen(false);
                  }}
                >
                  <Flex
                    flexDir="column"
                    gap="12px"
                    p="12px 24px"
                    bg="#f2f2f2"
                    borderRadius="12px"
                    pos="absolute"
                    mt="5px"
                    w="100%"
                    zIndex="1000"
                  >
                    {badgeTypes?.[0]?.types?.map((type: string, idx: any) => (
                      <Flex
                        align="center"
                        cursor="pointer"
                        justify="space-between"
                        key={idx}
                        onClick={() => {
                          setIsDropOpen(false);
                          setSelectedBadge(type);
                        }}
                      >
                        <CustomText
                          text={type}
                          size="12px"
                          color="yess.nuetral900"
                          cursor="pointer"
                        />
                        {selectedBadge === type && (
                          <IconCheck size="12px" color="#000000" />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText
                text="Valid For"
                size="14px"
                color="yess.neutral400"
              />
              <FormInput
                value={validFor}
                onChange={(e: any) => {
                  setValidFor(e.target.value);
                }}
                h="48px"
                ph="Enter Valid Duration"
              />
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText
                text="Organizations"
                size="14px"
                color="yess.neutral400"
              />
              <Box pos="relative">
                <Flex
                  h="48px"
                  align="center"
                  justify="space-between"
                  px="20px"
                  borderRadius="8px"
                  bg="#F2F2F2"
                  cursor="pointer"
                  onClick={() => {
                    setIsOrgOpen(true);
                  }}
                >
                  <CustomText
                    text={
                      organizations?.find(
                        (tempOrg: any) => tempOrg?.docId === org
                      )?.companyInfo?.name || "Select Organization"
                    }
                    size="14px"
                    color="yess.neutral600"
                  />
                  <IconChevronDown />
                </Flex>
                <OutsideClickHandler
                  isOpen={isOrgOpen}
                  onClose={() => {
                    setIsOrgOpen(false);
                  }}
                >
                  <Flex
                    flexDir="column"
                    gap="12px"
                    p="12px 24px"
                    bg="#f2f2f2"
                    borderRadius="12px"
                    pos="absolute"
                    mt="5px"
                    w="100%"
                    zIndex="1000"
                  >
                    {organizations?.map((tempOrg: any, idx: any) => (
                      <Flex
                        align="center"
                        cursor="pointer"
                        justify="space-between"
                        key={idx}
                        onClick={() => {
                          setOrg(tempOrg?.docId);
                          setIsOrgOpen(false);
                        }}
                      >
                        <CustomText
                          text={tempOrg?.companyInfo?.name}
                          size="12px"
                          color="yess.nuetral900"
                          cursor="pointer"
                        />
                        {tempOrg?.docId === org && (
                          <IconCheck size="12px" color="#000000" />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText text="User" size="14px" />
              <Select
                options={visitors
                  ?.filter((visitor: any) => !visitor?.badgeAssigned)
                  ?.map((visitor: any) => ({
                    value: visitor?.docId,
                    label: visitor?.personalInfo?.name,
                  }))}
                value={selectedUser}
                onChange={(selectedOption: any) => {
                  setSelectedUser(selectedOption);
                }}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: "#f2f2f2",
                    border: "none",
                    height: "48px",
                  }),
                }}
              />
            </Flex>
            <Flex justify="end">
              <CustomButton
                btnTitle="Assign"
                bg="yess.secondary_gold"
                weight="400"
                onClick={() => {
                  if (!org) {
                    toast({
                      title: "Please select an organization",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  if (!selectedUser) {
                    toast({
                      title: "Please select a user",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  if (!validFor) {
                    toast({
                      title: "Please enter a valid duration",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  if (!selectedBadge) {
                    toast({
                      title: "Please select a badge type",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  handleAssignSingleBadge();
                }}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
      <Modal isOpen={isBulkOpen} onClose={onBulkClose}>
        <ModalOverlay />
        <ModalContent maxW="600px" borderRadius="16px">
          <Flex
            p="24px"
            align="center"
            justify="space-between"
            borderBottom="1px solid #CFCECE"
          >
            <CustomText
              text="Assign Bulk Badges"
              color="yess.base_black"
              size="20px"
            />
            <IconX
              color="#000"
              onClick={() => {
                onBulkClose();
                clearStates();
              }}
            />
          </Flex>
          <Flex flexDir="column" gap="24px" p="24px">
            <Flex flexDir="column" gap="11px">
              <CustomText text="Type" size="14px" color="yess.neutral400" />
              <Box pos="relative">
                <Flex
                  h="48px"
                  align="center"
                  justify="space-between"
                  px="20px"
                  borderRadius="8px"
                  bg="#F2F2F2"
                  cursor="pointer"
                  onClick={() => {
                    setIsDropOpen(true);
                  }}
                >
                  <CustomText
                    text={selectedBadge}
                    size="14px"
                    color="yess.neutral600"
                  />
                  <IconChevronDown />
                </Flex>
                <OutsideClickHandler
                  isOpen={isDropOpen}
                  onClose={() => {
                    setIsDropOpen(false);
                  }}
                >
                  <Flex
                    flexDir="column"
                    gap="12px"
                    p="12px 24px"
                    bg="#f2f2f2"
                    borderRadius="12px"
                    pos="absolute"
                    mt="5px"
                    w="100%"
                    zIndex="1000"
                  >
                    {badgeTypes?.[0]?.types?.map((type: string, idx: any) => (
                      <Flex
                        align="center"
                        cursor="pointer"
                        justify="space-between"
                        key={idx}
                        onClick={() => {
                          setIsDropOpen(false);
                          setSelectedBadge(type);
                        }}
                      >
                        <CustomText
                          text={type}
                          size="12px"
                          color="yess.nuetral900"
                          cursor="pointer"
                        />
                        {selectedBadge === type && (
                          <IconCheck size="12px" color="#000000" />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText
                text="Valid For"
                size="14px"
                color="yess.neutral400"
              />
              <FormInput
                value={validFor}
                onChange={(e: any) => {
                  setValidFor(e.target.value);
                }}
                h="48px"
                ph="Enter Valid Duration"
              />
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText
                text="Organizations"
                size="14px"
                color="yess.neutral400"
              />
              <Box pos="relative">
                <Flex
                  h="48px"
                  align="center"
                  justify="space-between"
                  px="20px"
                  borderRadius="8px"
                  bg="#F2F2F2"
                  cursor="pointer"
                  onClick={() => {
                    setIsOrgOpen(true);
                  }}
                >
                  <CustomText
                    text={
                      organizations?.find(
                        (tempOrg: any) => tempOrg?.docId === org
                      )?.companyInfo?.name || "Select Organization"
                    }
                    size="14px"
                    color="yess.neutral600"
                  />
                  <IconChevronDown />
                </Flex>
                <OutsideClickHandler
                  isOpen={isOrgOpen}
                  onClose={() => {
                    setIsOrgOpen(false);
                  }}
                >
                  <Flex
                    flexDir="column"
                    gap="12px"
                    p="12px 24px"
                    bg="#f2f2f2"
                    borderRadius="12px"
                    pos="absolute"
                    mt="5px"
                    w="100%"
                    zIndex="1000"
                  >
                    {organizations?.map((tempOrg: any, idx: any) => (
                      <Flex
                        align="center"
                        cursor="pointer"
                        justify="space-between"
                        key={idx}
                        onClick={() => {
                          setOrg(tempOrg?.docId);
                          setIsOrgOpen(false);
                        }}
                      >
                        <CustomText
                          text={tempOrg?.companyInfo?.name}
                          size="12px"
                          color="yess.nuetral900"
                          cursor="pointer"
                        />
                        {tempOrg?.docId === org && (
                          <IconCheck size="12px" color="#000000" />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText text="User" size="14px" />
              <Select
                isMulti
                options={visitors
                  ?.filter((visitor: any) => !visitor?.badgeAssigned)
                  ?.map((visitor: any) => ({
                    value: visitor?.docId,
                    label: visitor?.personalInfo?.name,
                  }))}
                value={multiUsers}
                onChange={(selectedOption: any) => {
                  setMultiUsers(selectedOption);
                }}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: "#f2f2f2",
                    border: "none",
                    height: "48px",
                  }),
                }}
              />
            </Flex>
            <Flex justify="end">
              <CustomButton
                btnTitle="Assign"
                bg="yess.secondary_gold"
                weight="400"
                onClick={() => {
                  if (!org) {
                    toast({
                      title: "Please select an organization",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  if (!multiUsers?.length) {
                    toast({
                      title: "Please select a user",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  if (!validFor) {
                    toast({
                      title: "Please enter a valid duration",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  if (!selectedBadge) {
                    toast({
                      title: "Please select a badge type",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }

                  handleAssignBadge();
                }}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Badges;
