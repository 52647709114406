import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import CustomPhoneInput from "../../components/form/PhoneInput";

function AdminContact({ payload, setPayload }: any) {
  return (
    <Flex
      flexDir="column"
      gap="22px"
      maxW="666px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Admin Contact" family="aeonik-bold" align="center" />
      <CustomText
        text="Enter contact Info of the admin "
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="First Name" size="12px" />
          <FormInput
            ph="Enter First Name"
            maxW="322px"
            value={payload?.firstName}
            onChange={(e: any) =>
              setPayload({ ...payload, firstName: e.target.value })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Last Name" size="12px" />
          <FormInput
            ph="Enter Last Name"
            maxW="322px"
            value={payload?.lastName}
            onChange={(e: any) =>
              setPayload({ ...payload, lastName: e.target.value })
            }
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Email" size="12px" />
          <FormInput
            ph="Enter Email"
            maxW="322px"
            value={payload?.personalEmail}
            onChange={(e: any) =>
              setPayload({
                ...payload,
                personalEmail: e.target.value,
                inviteEmail: e.target.value,
              })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Phone Number" size="12px" />
          <CustomPhoneInput
            value={payload?.personalPhone}
            onChange={(e: any) => {
              setPayload({ ...payload, personalPhone: e, invitePhone: e });
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AdminContact;
