import React, { useEffect, useState } from "react";
import Stats from "./Stats";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex, Input } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RotateIcon from "../../svgs/RotateIcon";
import { IconCheck, IconChevronDown, IconPlus } from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import CustomButton from "../../components/buttons/CustomButton";
import OrganizationTable from "./OrganizationTable";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrganizations } from "../../redux/actions/organization.actions";

function Organizations() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { organizations } = useSelector((state: any) => state.organization);

  const [search, setSearch] = useState("");
  const [selectedTimer, setSelectedTimer] = useState("Every 10 mins");
  const [isTimerOpen, setIsTimerOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllOrganizations());
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Stats orgs={organizations} />
      <Box mt="24px">
        <CustomCard w="100%">
          <Flex align="center" justify="space-between">
            <Flex flexDir="column" w="100%">
              <CustomText
                text="Organization"
                size="18px"
                color="yess.base_black"
              />
              <Flex gap="8px" align="center">
                <CustomText
                  text="Last synced: Sept 15, 2024 10:30 AM"
                  size="12px"
                />
                <Flex
                  align="center"
                  gap="6px"
                  px="8px"
                  h="24px"
                  w="89px"
                  bg="yess.secondary_light_gray"
                  borderRadius="5px"
                >
                  <CustomText
                    text="Sync Data"
                    size="12px"
                    color="yess.secondary_blue"
                  />
                  <RotateIcon />
                </Flex>
                <Box pos="relative">
                  <Flex
                    align="center"
                    gap="12px"
                    px="8px"
                    h="24px"
                    w="116px"
                    bg="yess.secondary_light_gray"
                    borderRadius="5px"
                    cursor="pointer"
                    justify="space-between"
                    onClick={() => setIsTimerOpen(!isTimerOpen)}
                  >
                    <CustomText
                      text={selectedTimer}
                      size="12px"
                      color="yess.nuetral900"
                    />
                    <IconChevronDown size="12px" color="#000000" />
                  </Flex>
                  <OutsideClickHandler
                    isOpen={isTimerOpen}
                    onClose={() => setIsTimerOpen(false)}
                  >
                    <Flex
                      flexDir="column"
                      bg="#f2f2f2"
                      p=" 6px 6px"
                      pos="absolute"
                      mt="5px"
                      borderRadius="6px"
                      zIndex="100"
                      gap="2px"
                    >
                      {["Every 10 mins", "Every 30 mins", "Every 1 hour"]?.map(
                        (timer, idx) => (
                          <>
                            <Flex
                              align="center"
                              gap="12px"
                              px="8px"
                              h="24px"
                              w="116px"
                              bg="yess.secondary_light_gray"
                              borderRadius="5px"
                              cursor="pointer"
                              justify="space-between"
                              onClick={() => {
                                setSelectedTimer(timer);
                                setIsTimerOpen(false);
                              }}
                            >
                              <CustomText
                                text={timer}
                                size="12px"
                                color="yess.nuetral900"
                              />
                              {selectedTimer === timer && (
                                <IconCheck size="12px" color="#000000" />
                              )}
                            </Flex>
                            <Divider bg="#f2f2f2" />
                          </>
                        )
                      )}
                    </Flex>
                  </OutsideClickHandler>
                </Box>
              </Flex>
            </Flex>
            <Flex align="center" gap="10px" w="100%" justify="end">
              <Flex
                ps="6px"
                pe="16px"
                py="8px"
                height="48px"
                maxW="360px"
                w="100%"
                borderRadius="12px"
                border="1px solid"
                borderColor="yess.border"
                bg="yess.secondary_light_gray"
                align="center"
                gap="8px"
              >
                <Flex
                  minH="28px"
                  minW="28px"
                  borderRadius="4px"
                  bg="#fff"
                  align="center"
                  justify="center"
                >
                  <SortIcon />
                </Flex>
                <Input
                  placeholder="Search..."
                  color="yess.neutral900"
                  fontSize="12px"
                  fontWeight="400"
                  pl="0px"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="aeonik-regular"
                  _placeholder={{
                    color: "yess.neutral700",
                  }}
                  bg="transparent"
                  border="none"
                  _focus={{ border: "none" }}
                  _hover={{ border: "none" }}
                  _focusVisible={{ border: "none" }}
                />
                <SearchIcon />
              </Flex>

              <CustomButton
                btnTitle="Create Organization"
                size="12px"
                weight="400"
                color="#fff"
                h="48px"
                bg="yess.secondary_gold"
                radius="12px"
                border="1px solid"
                borderColor="yess.secondary_gold"
                maxW="160px"
                leftIco={<IconPlus color="#fff" size="14px" />}
                onClick={() => navigate("/organizations/create-organization")}
              />
            </Flex>
          </Flex>
          <OrganizationTable organizations={organizations} search={search} />
        </CustomCard>
      </Box>
    </div>
  );
}

export default Organizations;
