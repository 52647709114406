import React, { useEffect, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Box,
  Divider,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RotateIcon from "../../svgs/RotateIcon";
import {
  IconCheck,
  IconChevronDown,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import CustomButton from "../../components/buttons/CustomButton";
import FormInput from "../../components/form/FormInput";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import PromoTable from "./PromoTable";
import { useDispatch, useSelector } from "react-redux";
import { addPromo, getAllPromos } from "../../redux/actions/promo.actions";
import { getAllOrganizations } from "../../redux/actions/organization.actions";

function PromoCode() {
  const dispatch: any = useDispatch();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { promos, promoLoader } = useSelector((state: any) => state.promo);
  const { organizations } = useSelector((state: any) => state.organization);

  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [isTimerOpen, setIsTimerOpen] = useState(false);
  const [selectedTimer, setSelectedTimer] = useState("Every 10 mins");
  const [payload, setPayload] = useState({
    name: "",
    discount: "",
    code: "",
    status: "",
    redeems: "",
    created_date: "",
    organization: "",
  });

  const handlePromoCreation = () => {
    if (!payload.name || !payload.discount || !payload.code) {
      toast({
        title: "Fields are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newPromos: any = {
      ...payload,
      status: "Active",
      redeems: 0,
      created_date: new Date().toDateString(),
    };

    dispatch(addPromo(newPromos));
    onClose();
    setPayload({
      name: "",
      discount: "",
      code: "",
      status: "",
      redeems: "",
      created_date: "",
      organization: "",
    });
  };

  useEffect(() => {
    dispatch(getAllOrganizations());
    dispatch(getAllPromos());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomCard w="100%">
        <Flex align="center" justify="space-between">
          <Flex flexDir="column" w="100%">
            <CustomText text="Promos" size="18px" color="yess.base_black" />
            <Flex gap="8px" align="center">
              <CustomText
                text="Last synced: Oct 21, 2024 10:30 AM"
                size="12px"
              />
              <Flex
                align="center"
                gap="6px"
                px="8px"
                h="24px"
                w="89px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Sync Data"
                  size="12px"
                  color="yess.secondary_blue"
                />
                <RotateIcon />
              </Flex>
              <Box pos="relative">
                <Flex
                  align="center"
                  gap="12px"
                  px="8px"
                  h="24px"
                  w="116px"
                  bg="yess.secondary_light_gray"
                  borderRadius="5px"
                  cursor="pointer"
                  justify="space-between"
                  onClick={() => setIsTimerOpen(!isTimerOpen)}
                >
                  <CustomText
                    text={selectedTimer}
                    size="12px"
                    color="yess.nuetral900"
                  />
                  <IconChevronDown size="12px" color="#000000" />
                </Flex>
                <OutsideClickHandler
                  isOpen={isTimerOpen}
                  onClose={() => setIsTimerOpen(false)}
                >
                  <Flex
                    flexDir="column"
                    bg="#f2f2f2"
                    p=" 6px 6px"
                    pos="absolute"
                    mt="5px"
                    borderRadius="6px"
                    zIndex="100"
                    gap="2px"
                  >
                    {["Every 10 mins", "Every 30 mins", "Every 1 hour"]?.map(
                      (timer, idx) => (
                        <>
                          <Flex
                            align="center"
                            gap="12px"
                            px="8px"
                            h="24px"
                            w="116px"
                            bg="yess.secondary_light_gray"
                            borderRadius="5px"
                            cursor="pointer"
                            justify="space-between"
                            onClick={() => {
                              setSelectedTimer(timer);
                              setIsTimerOpen(false);
                            }}
                          >
                            <CustomText
                              text={timer}
                              size="12px"
                              color="yess.nuetral900"
                            />
                            {selectedTimer === timer && (
                              <IconCheck size="12px" color="#000000" />
                            )}
                          </Flex>
                          <Divider bg="#f2f2f2" />
                        </>
                      )
                    )}
                  </Flex>
                </OutsideClickHandler>
              </Box>
            </Flex>
          </Flex>
          <Flex align="center" justify="end" gap="10px" w="100%">
            <Flex
              ps="6px"
              pe="16px"
              py="8px"
              height="48px"
              maxW="360px"
              w="100%"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.border"
              bg="yess.secondary_light_gray"
              align="center"
              gap="8px"
            >
              <Flex
                minH="28px"
                minW="28px"
                borderRadius="4px"
                bg="#fff"
                align="center"
                justify="center"
              >
                <SortIcon />
              </Flex>
              <Input
                placeholder="Search "
                color="yess.neutral900"
                fontSize="12px"
                fontWeight="400"
                pl="0px"
                className="aeonik-regular"
                _placeholder={{
                  color: "yess.neutral700",
                }}
                bg="transparent"
                border="none"
                _focus={{ border: "none" }}
                _hover={{ border: "none" }}
                _focusVisible={{ border: "none" }}
              />
              <SearchIcon />
            </Flex>
            <CustomButton
              btnTitle="Create Promo"
              size="12px"
              weight="400"
              color="#fff"
              h="48px"
              bg="yess.secondary_gold"
              radius="12px"
              border="1px solid"
              borderColor="yess.secondary_gold"
              maxW="145px"
              leftIco={<IconPlus color="#fff" size="14px" />}
              onClick={() => onOpen()}
            />
          </Flex>
        </Flex>
        <PromoTable promos={promos} />
      </CustomCard>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent maxW="600px" borderRadius="16px">
          <Flex
            align="center"
            justify="space-between"
            p="24px"
            borderBottom="1px solid #CFCECE"
          >
            <CustomText
              text="Create New Admin"
              size="20px"
              color="yess.neutral_black"
            />
            <IconX
              onClick={() => {
                setPayload({
                  name: "",
                  discount: "",
                  code: "",
                  status: "",
                  redeems: "",
                  created_date: "",
                  organization: "",
                });
                onClose();
              }}
            />
          </Flex>
          <Flex flexDir="column" gap="24px" p="24px">
            <Flex flexDir="column" gap="12px">
              <CustomText text="Name" size="14px" />
              <FormInput
                h="48px"
                ph="Enter Name"
                value={payload?.name}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    name: e.target.value,
                  });
                }}
              />
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Discount" size="14px" />
              <FormInput
                h="48px"
                ph="Enter Discount"
                type="text"
                value={payload?.discount}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    discount: e.target.value,
                  });
                }}
              />
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Code" size="14px" />
              <FormInput
                h="48px"
                ph="Enter Code"
                type="text"
                value={payload?.code}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    code: e.target.value,
                  });
                }}
              />
            </Flex>
            <Box pos="relative">
              <Flex flexDir="column" gap="12px">
                <CustomText text="Organization" size="14px" />
                <Flex
                  h="48px"
                  bg="#f2f2f2"
                  align="center"
                  justify="space-between"
                  borderRadius="6px"
                  px="24px"
                  cursor="pointer"
                  onClick={() => setIsOrgOpen(!isOrgOpen)}
                >
                  <CustomText
                    text={
                      organizations?.find(
                        (tempOrg: any) =>
                          tempOrg?.docId === payload?.organization
                      )?.companyInfo?.name || "Select Organization"
                    }
                    size="14px"
                    color="#292D32"
                  />
                  <IconChevronDown />
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                bg="#f2f2f2"
                p=" 6px 6px"
                pos="absolute"
                mt="5px"
                borderRadius="6px"
                zIndex="100"
                gap="2px"
                w="100%"
                display={isOrgOpen ? "flex" : "none"}
              >
                {organizations?.map((org: any, idx: number) => (
                  <>
                    <Flex
                      key={idx}
                      align="center"
                      gap="12px"
                      px="8px"
                      h="24px"
                      justifyContent="space-between"
                      bg="yess.secondary_light_gray"
                      borderRadius="5px"
                      cursor="pointer"
                      justify="space-between"
                      onClick={() => {
                        setPayload({
                          ...payload,
                          organization: org?.docId,
                        });
                        setIsOrgOpen(false);
                      }}
                    >
                      <CustomText
                        text={org?.companyInfo?.name}
                        size="12px"
                        color="yess.nuetral900"
                      />
                      {payload.organization === org?.docId && (
                        <IconCheck size="12px" color="#000000" />
                      )}
                    </Flex>
                    <Divider bg="#f2f2f2" />
                  </>
                ))}
              </Flex>
            </Box>
            <Flex justify="end">
              <CustomButton
                btnTitle="Create Promo"
                weight="400"
                bg="yess.secondary_gold"
                isLoading={promoLoader}
                onClick={handlePromoCreation}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PromoCode;
