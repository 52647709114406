import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";

function Quota({ payload, setPayload }: any) {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      maxW="666px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Quota" family="aeonik-bold" align="center" />
      <CustomText
        text="Assign quota to organization"
        align="center"
        color="yess.neutral600"
      />

      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Quota" size="12px" />
        <FormInput
          ph="Enter Quota"
          value={payload?.quota}
          onChange={(e: any) =>
            setPayload({ ...payload, quota: e.target.value })
          }
        />
      </Flex>
    </Flex>
  );
}

export default Quota;
