import React, { useEffect } from "react";
import IconUsers from "../../svgs/IconUsers";
import CustomCard from "../../components/cards/CustomCard";
import { Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import PillButton from "../../components/buttons/PillButton";
import { getAllExhibitors } from "../../redux/actions/exhibitors.actions";
import { useDispatch, useSelector } from "react-redux";

function TotalExhibitos() {
  const dispatch: any = useDispatch();
  const { exhibitors } = useSelector((state: any) => state.exhibitors);
  useEffect(() => {
    dispatch(getAllExhibitors());
    // eslint-disable-next-line
  }, []);

  return (
    <CustomCard minH="280px" minW="300px">
      <Flex justify="space-between" flexDir="column" gap="24px" minH="100%">
        <Flex flexDir="column" gap="24px">
          <Flex align="center" gap="12px">
            <Flex
              bg="yess.secondary_light_gray"
              borderRadius="8px"
              align="center"
              justify="center"
              minH="40px"
              minW="40px"
            >
              <IconUsers />
            </Flex>
            <CustomText text="Total Exhibitors" color="yess.base_black" />
          </Flex>
          <CustomText
            text={Number(exhibitors?.length || 0)?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            size="40px"
            color="yess.base_black"
          />
        </Flex>
        <Flex
          borderTop="1px solid #E5E7EB"
          pt="12px"
          align="center"
          justify="space-between"
        >
          <CustomText
            text="Currently Available"
            size="14px"
            color="yess.neutral1000"
          />
          <PillButton title={`${exhibitors?.length} Exhibitors`} />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default TotalExhibitos;
