const initState = {
  loading: false,
  registered: false,
};

const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "REGISTER_SUCCESS":
      return {
        ...state,
        registered: true,
      };
    case "REGISTER_FAIL":
      return {
        ...state,
      };
    case "REGISTER_COMPLETE":
      return {
        ...state,
        registered: false,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        registered: false,
      };
    case "AUTH_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
